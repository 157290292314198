import React from 'react';
import OutboundLink from '../analytics/components/OutboundLink'


class ErrorBoundary extends React.Component {
    constructor(props) {
    super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
        console.log("Error: " + error + " - " + info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Something went wrong.</h1>
                    <a href="https://united.com" target="_blank">Go to United</a>
                </div>
            );
        }

        return this.props.children; 
    }
}

export default ErrorBoundary