import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { withTranslation } from 'react-i18next'

import { getSecurityQuestions, processAuthQuestions } from '../../actions'

import SecurityQuestions from '../SecurityQuestions'


export class PasswordRecoverySecurityQuestions extends Component {
    componentDidMount() {
        const newSessionToken = null;
        this.props.getSecurityQuestions(this.props.login.account, newSessionToken);
    }

    componentDidUpdate(prevProps) {
        //call getSecurityQuestions a second time (with the sessionToken from the first response) to get a third question
        if(this.props.processAuthQuestionsStatus !== prevProps.processAuthQuestionsStatus
            && this.props.processAuthQuestionsStatus === "Continue") {
                this.props.getSecurityQuestions(this.props.login.account, this.props.sessionToken);
        }
    }

    render() {
        //no account, return to login
        if(!this.props.login || !this.props.login.account){
            console.log("Account missing");
            window.newrelic.interaction().save();
            return(<Redirect to="/login" />);
        }

        const { t } = this.props;
        switch(this.props.processAuthQuestionsStatus) {
            //TODO: I don't see any processAuthQuestion responses in proxy-int in the last 3 months with any statuses other than 5 (AllowResetPassword) and 7 (ForgetPasswordEmailSent). Maybe we don't need these cases.
            // case "Locked":
            //     window.newrelic.interaction().save();    
            //     return(<Redirect to={{ pathname: '/terminate', state: {message: t('Account Locked')} }} />);
            // case "LockedAccount":
            //     window.newrelic.interaction().save();
            //     return(<Redirect to={{ pathname: '/terminate', state: {message: t('Account Locked')} }} />);
            
            //Password recovery
            case "ForgetPasswordEmailSent":
                window.newrelic.interaction().save();
                return(<Redirect to={{ pathname: '/terminate', state: {message: t('Forgot password email sent.')}} } />);
            case "AllowResetPassword":
                window.newrelic.interaction().save();
                return(<Redirect to={{ pathname: '/passwordRecovery/updatePassword'}} />);

            //no processAuthQuestionsStatus = haven't started yet, 2 questions required
            //or 1 more question required, call getSecurityQuestions with sessionToken
            default: 
                return(
                    <SecurityQuestions 
                        securityQuestions={this.props.securityQuestions} 
                        sessionToken={this.props.sessionToken}
                        processAuthQuestions={this.props.processAuthQuestions}
                        isPasswordRecovery={true}
                        addDeviceAuthenticationAvailability={false}
                        addDeviceAuthentication={false}
                        // processAuthQuestionsStatus={this.props.processAuthQuestionsStatus}
                    />
                )
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...state,
        securityQuestions: state.Store.securityQuestions.securityQuestions,
        sessionToken: state.Store.securityQuestions.sessionToken,
        processAuthQuestionsStatus: state.Store.securityQuestions.processAuthQuestionsStatus,
        login: state.Store.login,
    }
}

function mapDispatchToProps(dispatch, actions) {
    return {
        getSecurityQuestions: function(account, sessionToken){ dispatch(getSecurityQuestions(account, sessionToken)) },
        processAuthQuestions: function(account, sessionToken, isPasswordRecovery, addDeviceAuthentication, authConfigToken, sidOnlineAuthCode, question1, answer1, question2, answer2){
            // call process auth questions with authConfigToken and sid auth codes for login to get authConfig auth codes
            // call process auth questions without to get sid auth codes for password recovery
            const emptyAuthConfigToken = "";
            const emptySidOnlineAuthCode = "";
            dispatch(processAuthQuestions(account, sessionToken, true, false, emptyAuthConfigToken, emptySidOnlineAuthCode, question1, answer1, question2, answer2))
        },
    }
}

const trans = withTranslation('passwordRecovery_securityQuestions')(PasswordRecoverySecurityQuestions)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(trans)