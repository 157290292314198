import React, { Component } from 'react'
import Container from './Container'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import H2 from './styling/H2'
import P from './styling/P'

export const StyledContent = styled.div`
  font-family: ${props => props.theme.font};
  font-size: 24px;
`

const captionMargin = '112px 0px 11px 0px';

export class ErrorPage extends Component {
    render() {
        const { t } = this.props;
        const error =  (this.props.location && this.props.location.state && this.props.location.state.error) ?
            this.props.location.state.error
            : t('Something went wrong')

        return (
            <Container captionMargin={captionMargin} {...this.props}>
                <StyledContent>
                    <H2>{t('Error')}</H2>
                    <P>
                        {error}
                    </P>
                </StyledContent>
            </Container>
        );
    }
}

export default withTranslation('error')(ErrorPage)
