import React, { Component } from 'react'
import styled from 'styled-components'
import OutboundLink from '../../analytics/components/OutboundLink'
import Button from './Button'


const StyledOutboundLink = styled.a`
    text-decoration: none;
    color: #FFFFFF;
`;

class StyledOutboundLinkButton extends Component {
    render() {
        const { eventLabel, to, disabled, loading, className, children, ...others } = this.props;
        return (
            <StyledOutboundLink href={to} target="_blank">
                <Button className={className} disabled={disabled} loading={loading} {...others} >
                    { children }
                </Button>
            </StyledOutboundLink>
        )
    }
}

export default StyledOutboundLinkButton;