import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSecurityQuestions, processAuthQuestions } from '../actions'
import { Redirect } from 'react-router'
import SecurityQuestions from './SecurityQuestions'
import { withTranslation } from 'react-i18next';

import Container from './Container'
import LargeSpinner from'./styling/LargeSpinner'



function addUrlQueryParam(baseUrl, paramName, paramValue){
    if(!baseUrl){
        return baseUrl;
    } else if(baseUrl.endsWith("?")){
        return baseUrl + paramName + "=" + paramValue;
    } else if (baseUrl.includes("?")) {
        return baseUrl + "&" + paramName + "=" + paramValue;
    } else {
        return baseUrl + "?" + paramName + "=" + paramValue;
    }
}

export class LoginSecurityQuestions extends Component {
    componentDidMount() {
        const newSessionToken = null;
        this.props.getSecurityQuestions(this.props.login.account, newSessionToken);
    }

    componentDidUpdate(prevProps) {
        //call getSecurityQuestions a second time (with the sessionToken from the first response) to get a third question
        if(this.props.processAuthQuestionsStatus !== prevProps.processAuthQuestionsStatus
            && this.props.processAuthQuestionsStatus === "Continue") {
                this.props.getSecurityQuestions(this.props.login.account, this.props.sessionToken);
        }
    }

    render() {
        //no account, return to login
        if(!this.props.login || !this.props.login.account){
            window.newrelic.interaction().save();
            return(<Redirect to="/login" />);
        }

        const { t }  = this.props;
        switch(this.props.processAuthQuestionsStatus) {
            case "Success":
                if(this.props.login && this.props.login.authConfigOnlineAuthCode){
                    console.log("Two factor login successful");
                    let url = this.props.authConfig.redirectUrl;
                
                    url = addUrlQueryParam(url, "onlineAuthCode", this.props.login.authConfigOnlineAuthCode);
                    url = addUrlQueryParam(url, "offlineAuthCode", this.props.login.authConfigOfflineAuthCode);
                
                    window.location.assign(url);
                    return(
                        <Container>
                            <LargeSpinner/>
                        </Container>
                    );
                } else {
                    console.log('Error: ProcessAuthQuestions returned "Success" with no authCodes');
                    return(<Redirect to="/login" />);
                }
            case "Locked":
                window.newrelic.interaction().save();    
                return(<Redirect to={{ pathname:'/terminate', state: {message: t('Account Locked')} }} />);
            case "LockedAccount":
                window.newrelic.interaction().save();    
                return(<Redirect to={{ pathname:'/terminate', state: {message: t('Account Locked')} }} />);

            //no processAuthQuestionsStatus = haven't started yet, 2 questions required
            //or 1 more question required, call getSecurityQuestions with sessionToken
            default:
                return(
                    <SecurityQuestions 
                        securityQuestions={this.props.securityQuestions} 
                        sessionToken={this.props.sessionToken}
                        processAuthQuestions={this.props.processAuthQuestions}
                        isPasswordRecovery={false}
                        addDeviceAuthenticationAvailability={this.props.addDeviceAuthenticationAvailability || false}
                        addDeviceAuthentication={false}
                        // processAuthQuestionsStatus={this.props.processAuthQuestionsStatus}
                    />
                );
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...state,
        securityQuestions: state.Store.securityQuestions.securityQuestions,
        sessionToken: state.Store.securityQuestions.sessionToken,
        processAuthQuestionsStatus: state.Store.securityQuestions.processAuthQuestionsStatus,
        addDeviceAuthenticationAvailability: state.Store.login.addDeviceAuthenticationAvailability,
        addDeviceAuthentication: state.Store.securityQuestions.addDeviceAuthentication,
        authConfig: state.Store.authConfig,
        login: state.Store.login,
    }
}

function mapDispatchToProps(dispatch, actions) {
    return {
        getSecurityQuestions: function(account, sessionToken){ dispatch(getSecurityQuestions(account, sessionToken)) },
        processAuthQuestions: function(account, sessionToken, isPasswordRecovery, addDeviceAuthentication, authConfigToken, sidOnlineAuthCode, question1, answer1, question2, answer2){ 
            // call process auth questions with authConfigToken and sid auth codes for login to get authConfig auth codes
            // call process auth questions without to get sid auth codes for password recovery
            dispatch(processAuthQuestions(account, sessionToken, false, addDeviceAuthentication, authConfigToken, sidOnlineAuthCode, question1, answer1, question2, answer2))
        },
    }
}

const translatedLoginSecurityQuestions = withTranslation('loginQuestions')(LoginSecurityQuestions);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translatedLoginSecurityQuestions)