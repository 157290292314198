import React, { Component } from 'react';
import './App.css';

import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import ApplicationRouter from './Router'
import store from './store'
import ErrorBoundary from './components/ErrorBoundary'


const theme = {
    colors: {
        success: {
            greenOnYellow: '#035C01',
            greenOnBlue: '#0B4409',
        },
        error: {
            redOnYellow: '#A00903',
            redOnBlue: '#790704',
        },
    },
    minWidth: {
        atto: '321px',
        femto: '441px',
        pico: '481px',
        nano: '641px',
        micro: '768px',
        milli: '796px',
        kilo: '961px',
        mega: '992px',
        giga: '1025px',
        tera: '1281px',
    },
}

class App extends Component {
    render() {
     return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <ApplicationRouter />
                </ErrorBoundary>
            </ThemeProvider>
        </Provider>
     );
  }
}

export default App;
