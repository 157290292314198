export function parseError(action) {
    //action.error should only exist if the response code is non 2xx or there was no response
    //https://www.npmjs.com/package/axios#handling-errors
    if (action && action.error) {
        try {
            console.error("Request Failed - %s", action.error);
        } catch(e) {}

        if (action.error.response) {
            if (action.error.response.data && action.error.response.data.exception) {
                return action.error.response.data.exception.exceptionMessage
                    || action.error.response.data.exception
            }
            return action.error.response.data || "Request failed"
        } 
        //the request was made but no response was received
        //error.request is just the XMLHttpRequest request object
        //else if (action.error.request) {}

        return action.error.message || "Request failed"
    }
    
    //action.payload.data should always exist unless there was an error
    //https://www.npmjs.com/package/redux-axios-middleware
    if (!(action && action.payload && action.payload.data)) {
        try {
            console.error("Request failed - %s", action)
        } catch(e) {}
        return "Request failed"
    }
    
    //check for exceptions within a success response
    //exception: {exceptionLocation: "CR", exceptionCode: 999, exceptionMessage: "Unknown error"}
    if (action.payload.data.exception) {
        const exception = action.payload.data.exception.exceptionMessage ? action.payload.data.exception.exceptionMessage
            : action.payload.data.exception
        try {
            console.error("Request failed - %s", exception)
        } catch(e) {}
        return exception
    }

    //I don't know if this path is actually used by anything but I saw it in updateEmailSuccess
    if (action.payload.exception) {
        try {
            console.error("Request failed - %s", action.payload.exception)
        } catch(e) {}
        return action.payload.exception
    }

    return ''
}

export default parseError