import { Actions } from '../../actions';
import parseError from './parseError';


export function authCodeReducer(state={}, action) {
    switch(action.type){
        case Actions.getAuthCodeData:
            return {
                ...state,
                loading: true,
                error: '',
            }
        case Actions.getAuthCodeDataSuccess:
            //this service only returns auth codes if the provided auth code is still valid (not expired)
            const getAuthCodeDataSuccessError = parseError(action);
            if (getAuthCodeDataSuccessError) {
                return {
                    ...state,
                    loading: false,
                    error: getAuthCodeDataSuccessError,
                }
            }

            return {
                ...state,
                ...action.payload.data,
                loading: false,
                error: '',
            }
        case Actions.getAuthCodeDataFail:
            return {
                ...state,
                loading: false,
                error: 'Failed to use provided auth code',
            }

        default:
            return state
    }
}

export default authCodeReducer