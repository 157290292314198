import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import Label from './Label'
import ErrorMessage from './ErrorMessage'


const StyledFieldWrapper = styled.div`
     display:flex;
     flex-direction:column;
     margin-top: 10px;
`;

export class FieldWrapper extends Component {

    render() {
        // var { name, className, required, label, component, type, id, validate, handleChange, handleBlur, children, errors, touched, style, tabindex } = this.props;
        var { name, className, required, label, children, errors, touched } = this.props;

        return(
            <StyledFieldWrapper key={name}>
                <Label className={className}>
                    {((required)?'* ':'') + label}
                </Label>
                {children}

                {
                    errors && touched && errors[name] && touched[name] && <ErrorMessage>{this.props.t(errors[name])}</ErrorMessage>
                }
            </StyledFieldWrapper>
        )
    }
}

export default withTranslation('field')(FieldWrapper)
