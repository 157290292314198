import "pidcrypt/seedrandom";
import pidCrypt from 'pidcrypt';
import "pidcrypt/rsa";
import "pidcrypt/asn1";
import pidCryptUtil from 'pidcrypt/pidcrypt_util';


export function encrypt(unencrypted) {
    if(!unencrypted){
        console.error("Invalide value passed to encrypt function: " + unencrypted);
        return unencrypted;
    }
    
    // var publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAse6LiDgb9R06jhug29pb" +
    // "n5g4aSdOuTs6tz5FJvhsLD8tkp1rLjIOoyjr5fsQerYt3yW1feyRrb4DLRDuHoNk" +
    // "XpkSMGAOdgZAYoPlKl+gyYS3cnuBwcb+YobX5JYZrg2CIuLmRPn93hKCdHLT+fdW" +
    // "ilXmuz7OUBBH73n1G1j+SS0KHMOhmXCN2XITwc2Sjdy15SAEkcY0beUh9RVIDK8b" +
    // "Hrd7UKdZkbiTqN6WU8rPLc/0p2FF3GAT96WchQFfyzcnBcHnxDtXkVmYoAyC/k5c" +
    // "Y8v70qrLkQnhM7Sl8TZ++Tl41hrW09n6zEGknG0FfLiU6QUON+ec+Y2NejoUFWel" +
    // "swIDAQAB";
    var publicKey = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;

    const key = pidCryptUtil.decodeBase64(publicKey);

    var rsa = new pidCrypt.RSA();
    var asn = pidCrypt.ASN1.decode(pidCryptUtil.toByteArray(key));
    var tree = asn.toHexTree();
    rsa.setPublicKeyFromASN(tree);

    var encrypted = rsa.encryptRaw(unencrypted);
    var fromHex = pidCryptUtil.encodeBase64(pidCryptUtil.convertFromHex(encrypted));
    var fragmented = pidCryptUtil.fragment(fromHex,64);

    return fragmented;
}

export function getFirstThatExists(labelsArray, object) {
    if(labelsArray.length < 1){
        return null;
    }
    for(let i in labelsArray){
        let label = labelsArray[i];

        for(let property in object){
            if(property.toUpperCase() === label.toUpperCase()){
                return object[property];
            }
        }
    }
    return null;
}

export function addUrlQueryParam(baseUrl, paramName, paramValue){
    if(!baseUrl){
        return baseUrl;
    } else if(baseUrl.endsWith("?")){
        return (baseUrl + paramName + "=" + paramValue);
    } else if (baseUrl.includes("?")) {
        return (baseUrl + "&" + paramName + "=" + paramValue);
    } else {
        return (baseUrl + "?" + paramName + "=" + paramValue);
    }
}