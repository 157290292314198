import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import mileageplus_logo from '../../images/logo_mileageplus_reverse.svg'

export const StyledHeader = styled.div`
    display: none;
    height: 91px;
    width: 100%;
    background-image: linear-gradient(0deg,#2172ba 0%,#1b4889 100%);
    background-repeat: repeat-x;

    @media (min-height: 900px) {
        display: block;
    }
`

export const MileagePlusLogo = styled.img`
    height: 52px;
    width: 158px;
    margin-top: 20px;
    margin-left: 20px; 
`

export class Header extends Component {
    render() {

        const { useUnitedLogo = true, partnerLogoUrl = mileageplus_logo } = this.props.authConfig;
        const logoSrc = (!useUnitedLogo && partnerLogoUrl) ? partnerLogoUrl : mileageplus_logo;

        return (
            <StyledHeader>
                <MileagePlusLogo src={logoSrc} alt={"MileagePlus Logo"}/>
            </StyledHeader>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authConfig: state.Store.authConfig,
    }
}

export default connect(
    mapStateToProps,
    null,
)(Header)
