import styled from 'styled-components'

const Title = styled.h1`
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-weight: 800px;
    color: #003057;

    @media (min-height: 700px){
        font-size: 40px;
        font-weight: 300;
    }
`;

export default Title