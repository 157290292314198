import styled from 'styled-components'

export default styled.div`
    display: none;
    line-height: 20px;
    font-size: 14px;

    @media (min-height: 700px){
        display: block;
    }
`

