import { combineReducers } from 'redux';
import authConfigReducer from './authConfigReducer';
import loginReducer from './loginReducer';
import securityQuestionReducer from './securityQuestionReducer';
import authCodeReducer from './authCodeReducer';
import ssoTokenReducer from './ssoTokenReducer';
import enrollReducer from './enrollReducer';
import passwordReducer from './passwordReducer';
import profileReducer  from './profileReducer';

export default combineReducers({
    authConfig: authConfigReducer,
    login: loginReducer,
    securityQuestions: securityQuestionReducer,
    authCode: authCodeReducer,
    ssoToken: ssoTokenReducer,
    enroll: enrollReducer,
    password: passwordReducer,
    profile: profileReducer,
})