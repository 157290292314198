import React from 'react'
import GoogleAnalytics from 'react-ga'

class OutboundLink extends React.PureComponent {
  render() {
    const { eventLabel, to, target, children, ...other } = this.props
    return (
      <GoogleAnalytics.OutboundLink eventLabel={eventLabel} to={to} target={target} {...other}>
        {children}
      </GoogleAnalytics.OutboundLink>
    )
  }
}

export default OutboundLink
