import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n'
import LargeSpinner from'./components/styling/LargeSpinner';
import GoogleAnalytics from 'react-ga4'


GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

ReactDOM.render(
    <Suspense fallback={<LargeSpinner/>}>
        <App/>
    </Suspense>,
    document.getElementById('root')
);

serviceWorker.unregister();
