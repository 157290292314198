import React, { Component } from 'react'
import styled from 'styled-components'

import LanguageSelector from './LanguageSelector'

import Footer from './footer/Footer';
import Header from './header/Header';
import Wrapper from './styling/Wrapper';
import Content from './styling/Content';


export const StyledContainer = styled.div`
    /*background-color: lightgrey;
    background-size: cover;*/
    font-family: ${props => props.theme.font};
    overflow: hidden;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    min-height: 100vh;
    min-width: 300px;
    width: 100%;
`

const FlexGrowContainer = styled.div`
  flex: 1;
`

const ContentDiv = styled.div`
    marginTop: 20px;
    clear: both;
`


//maybe rename this to something that we don't use to distinguish types of components
//maybe LoginLayout
export class Container extends Component {
  render() {
     const content = this.props.children || this.props.content;

     return (
        <main role="main">
          <Wrapper id="Internet Explorer Wrapper">
             <StyledContainer id="Container">
                <Header />
                <Content id="Content">
                    <LanguageSelector/>

                    <ContentDiv>
                        {content}
                    </ContentDiv>

                    <Footer />
                </Content>
                <FlexGrowContainer id="FlexGrowContainer" />
             </StyledContainer>
          </Wrapper>
        </main>
     );
  }
}

export default Container