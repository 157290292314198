import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Redirect } from 'react-router'
import Container from './Container'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import styled from 'styled-components'
import ReactGA from 'react-ga4'
import { encrypt } from '../functions/functions'

import { getAuthConfig, validateAccount, validateAccountInfo, setAuthConfigError, getAuthCodeData, decryptSsoToken } from '../actions'

import { translate } from '../functions/translate'
import { getFirstThatExists, addUrlQueryParam } from '../functions/functions'

import Form from './styling/Form'
import Field from './styling/Field'
import Button from './styling/Button'
import OutboundLink from './styling/OutboundLink'
import Link from './styling/Link'
import Title from './styling/Title'
import StyledErrorMessage from './styling/ErrorMessage'
import Subscript from './styling/Subscript'
import PasswordInput from './styling/PasswordInput'
import FieldWrapper from './styling/FieldWrapper'
import LargeSpinner from'./styling/LargeSpinner'


export const StyledFieldWrapper = styled(FieldWrapper)`
    margin-top: 5px;

    @media (min-height: 700px){
        margin-top: 25px;
    }
`

export const StyledField = styled(Field)`
    margin-top: 5px;

    @media (min-height: 700px){
        margin-top: 25px;
    }
`

export const EnrollmentLinkDiv = styled.div`
    display: none;
    margin: 25px 0;

    @media (min-height: 800px) {
        display: block;
    }
`

export const EnrollmentLinkMessage = styled.div`
    display: none;
    font-size: 20px;
    color: #000000;

    @media (min-height: 800px) {
        display: block;
    }
`

const passwordLoginSchema = Yup.object().shape({
    account: Yup.string()
        .required('Required')
        .matches(/^([a-zA-Z0-9]{6,11})$/, 'Invalid MP account number'),
    password: Yup.string()
        .required('Required')
});

const lastNameLoginSchema = Yup.object().shape({
    account: Yup.string()
        .required('Required')
        .matches(/^([a-zA-Z0-9]{6,11})$/, 'Invalid MP account number'),
    lastName: Yup.string()
        .required('Required')
});

export class Login extends Component {
    constructor(props) {
        super(props);

        const queryParams = queryString.parse(this.props.location.search);
        var cid = queryParams["CID"];
        const authCodeUrlParam = getFirstThatExists(["onlineAuthCode","offlineAuthCode","authenticationCode","authId"], queryParams);
        const ciToken = queryParams["ciToken"];
        const ckbr = queryParams["CKBR"];
        const loginFromPostRequest = queryParams["login"];
        const accountFromPostRequest = queryParams["account"];
        const epasswordFromPostRequest = queryParams["epassword"];
        const lastnameFromPostRequest = queryParams["lastname"];
        const forwardToUnitedLoginBridge = !ckbr;
        const sessionParam = queryParams["session"]

        this.state = {
            cid: cid,
            authCodeUrlParam: authCodeUrlParam,
            ciToken: ciToken,
            loginFromPostRequest: loginFromPostRequest,
            accountFromPostRequest: accountFromPostRequest,
            epasswordFromPostRequest: epasswordFromPostRequest,
            lastnameFromPostRequest: lastnameFromPostRequest,
            forwardToUnitedLoginBridge: forwardToUnitedLoginBridge,
            sessionParam: sessionParam,
        }
    }

    componentDidMount() {
        const { cid, ciToken } = this.state;
        //get auth config
        if(!this.props.authConfig.id){
            if(cid){
                this.props.getAuthConfig(cid);
            } else if(window.sessionStorage.getItem("cid")) {
                //re-use the last successfully loaded auth config if no CID is provided
                this.props.getAuthConfig(window.sessionStorage.getItem("cid"));
            } else {
                this.props.setAuthConfigError("Partner configuration cannot be found");
            }
        }

        //decrypt SSO token for Points only reuse of United session
        if(ciToken && cid) {
            this.props.decryptSsoToken(ciToken, cid);
        }
    }

    componentDidUpdate(prevProps){
        //set ga partner dimension
        if(
            this.props.authConfig
            && this.props.authConfig.partnerName
            && this.props.authConfig.partnerName !== prevProps.authConfig.partnerName
        ) {
            ReactGA.set({'dimension1': this.props.authConfig.partnerName});
        }

        //decode auth code (returns new auth codes if provided auth code is still valid i.e. renews auth code)
        const {authCodeUrlParam} = this.state
        if(
            authCodeUrlParam
            && this.props.authConfig
            && this.props.authConfig !== prevProps.authConfig
            && this.props.authConfig.token
            && !this.props.authConfig.forceRelogin
        ){
            this.props.getAuthCodeData(authCodeUrlParam, this.props.authConfig.token);
        }
    }

    render() {
        const { t, i18n } = this.props;
        const authConfig = this.props.authConfig;

        //auth config loading
        if(this.props.authConfig.loading) {
            return(
                <Container>
                    <LargeSpinner/>
                </Container>
            );
        }

        //check for missing login config
        if(this.props.authConfig && this.props.authConfig.id
            && !this.props.authConfig.loading
            && (!this.props.authConfig.loginConfigId || !this.props.authConfig.token)
            && !this.props.authConfig.error
        ){
            this.props.setAuthConfigError("Partner configuration cannot be found");
            //currently, an error in the authConfig object will cause a fallback to crew-widgets
        }

        //auth config error
        if(this.props.authConfig.error) {
            window.newrelic.interaction().save();

            //fall back on crew-widgets
            const widgetsRedirectUrl = process.env.REACT_APP_CREW_WIDGETS_URL + "/connect" + this.props.location.search;
            console.log('Falling back to crew-widgets: ' + widgetsRedirectUrl);
            window.location.assign(widgetsRedirectUrl);
            return(
                <Container>
                    <LargeSpinner />
                </Container>
            );
        }

        //use united sso
        if(this.props.authConfig && this.props.authConfig.useUnitedSso && this.state.cid) {
            let returnUrl = "CID=" + this.state.cid 
            if(this.state.sessionParam) {
                returnUrl += ("&S=" + this.state.sessionParam)
            }
            returnUrl = encodeURIComponent(returnUrl);
            console.log('Forward to United SSO: ' + process.env.REACT_APP_CREW_SERVICES_URL + "/CMSSO.jsp?RT=" + returnUrl);
            window.location.assign(process.env.REACT_APP_CREW_SERVICES_URL + "/CMSSO.jsp?RT=" + returnUrl);
            return(
                <Container>
                    <LargeSpinner/>
                </Container>
            );
        }

        //forward to United login bridge (CKBR) - not in stage (bridge doesn't work in stage)
        //don't use the login bridge if it's an SSO login
        if(process.env.REACT_APP_ENV === "production"
            && this.props.authConfig && this.props.authConfig.id && !this.props.authConfig.useUnitedSso
            && this.state.forwardToUnitedLoginBridge && !this.state.loginFromPostRequest)
        {
            const unitedLoginBridgeUrl = "https://www.united.com/web/en-US/apps/sso/loginBridge.aspx?target="
            const unitedLoginBridgeReturnUrl = encodeURIComponent(
                "/CM_connect.jsp?CKBR=1" 
                + (this.state.authCodeUrlParam ? "&AuthID=" + this.state.authCodeUrlParam : "")
                + "&CID=" + this.state.cid
                + "&redirect=sec&targetURLKey=crew.bridge.url&remove=false"
                + (this.state.sessionParam ? "&session=" + this.state.sessionParam : "")
            )

            console.log('Forward to United login bridge: ' + unitedLoginBridgeUrl + unitedLoginBridgeReturnUrl);
            window.location.assign(unitedLoginBridgeUrl + unitedLoginBridgeReturnUrl);

            return(
                <Container>
                    <LargeSpinner/>
                </Container>
            );
        }

        //security update
        /*
        - if there's anything in the updates array, redirect to the appropriate update page
        - update pages remove that item from the updates array when successful and redirect back to login
        - repeat if there's still anything in the updates array
        - don't complete login until updates array is empty
        - if there's a problem with the updates array it's probably safest to assume it's empty and continue
        - what happens first: security updates or two factor?
            > updates. We don't need to do two factor if there are updates to do (not sure why)
        - we need a sidAuthCode to do the updates but if there isn't one, the update component will just skip and return
        */
        if(this.props.login.accountItemsToUpdate
            && Array.isArray(this.props.login.accountItemsToUpdate)
            && this.props.login.accountItemsToUpdate.length > 0) 
        {
            const itemsToUpdate = this.props.login.accountItemsToUpdate;
            if (itemsToUpdate.includes("verifyemail")){
                window.newrelic.interaction().save();
                return(<Redirect to="/accountUpdates/updateEmail" />)
            } else if (itemsToUpdate.includes("password")){
                window.newrelic.interaction().save();
                return(<Redirect to="/accountUpdates/updatePassword" />)
            } else if (itemsToUpdate.includes("questions")){
                window.newrelic.interaction().save();
                return(<Redirect to="/accountUpdates/updateSecurityQuestions" />)
            }
        }
        
        //login completed
        if (
            //password login or lastName login
            //if !deviceAuthenticated we shouldnt have gotten an authConfig authCode back. Simplifying avoids dead states
            (this.props.login && this.props.login.authConfigOnlineAuthCode)
            //preauthorized login (authCode passed to crew login in url params)
            || (this.props.authCode && this.props.authCode.authConfigOnlineAuthCode)
            //decryptSSOToken (points only)
            || (this.props.ssoToken && this.props.ssoToken.authConfigOnlineAuthCode)
        ) {
            let url = authConfig.redirectUrl;
            if (this.props.login && this.props.login.authConfigOnlineAuthCode){
                url = addUrlQueryParam(url, "onlineAuthCode", this.props.login.authConfigOnlineAuthCode);
                url = addUrlQueryParam(url, "offlineAuthCode", this.props.login.authConfigOfflineAuthCode);
                if (this.state.sessionParam){
                    url = addUrlQueryParam(url, "session", this.state.sessionParam);
                }
            } 
            //preauthorized login
            else if (this.props.authCode && this.props.authCode.authConfigOnlineAuthCode) {
                url = addUrlQueryParam(url, "onlineAuthCode", this.props.authCode.authConfigOnlineAuthCode);
                url = addUrlQueryParam(url, "offlineAuthCode", this.props.authCode.authConfigOfflineAuthCode);
                if (this.state.sessionParam){
                    url = addUrlQueryParam(url, "session", this.state.sessionParam);
                }
            } 
            //decryptSSOToken (points only)
            else if (this.props.ssoToken && this.props.ssoToken.authConfigOnlineAuthCode) {
                //replace ssoSource in redirectUrl with "unitedlogin"
                try {
                    const redirectUrlPieces = url.split('?');
                    if (redirectUrlPieces.length === 2 && redirectUrlPieces[1].includes('ssoSource')){
                        const redirectUrlBase = redirectUrlPieces[0]
                        const redirectUrlQuery = redirectUrlPieces[1];
                        const redirectUrlParams = queryString.parse(redirectUrlQuery);
                        if (redirectUrlParams.ssoProduct !== 'transfer_request'){
                            redirectUrlParams.ssoSource = 'unitedlogin';
                            url = redirectUrlBase + '?' + queryString.stringify(redirectUrlParams)
                        }
                    }
                } catch(error) {
                    console.error(error)
                }

                url = addUrlQueryParam(url, "onlineAuthCode", this.props.ssoToken.authConfigOnlineAuthCode);
                url = addUrlQueryParam(url, "offlineAuthCode", this.props.ssoToken.authConfigOfflineAuthCode);
                if(this.state.sessionParam){
                    url = addUrlQueryParam(url, "session", this.state.sessionParam);
                }
            }
            
            console.log('Login complete');
            window.location.assign(url);

            return(
                <Container>
                    <LargeSpinner/>
                </Container>
            );
        }

        //two factor auth required
        if (this.props.login.sidOnlineAuthCode //sid auth code
            //it doesnt really matter if deviceAuthenticated is true or false
            //we have sid authCodes so we know auth was attempted but we didn't redirect so we still have to do something 
            // && this.props.login.deviceAuthenticated === false
            //we know there aren't any security update because we would have rendered and redirected above if they were
            // && (
            //     (Array.isArray(this.props.login.accountItemsToUpdate) && this.props.login.accountItemsToUpdate.length === 0)
            //     || this.props.login.accountItemsToUpdate == null
            // )
            //dont ask security questions for last name login
            && !authConfig.lastNameLogin
        ) {
            window.newrelic.interaction().save();
            return(
                <Redirect to="/loginSecurityQuestions" /> 
            );
        }

        //login from post request redirected from CM_connect.jsp to support program such as RewardsPlus
        //skip to form if login (validate) has error to show the error
        if (this.props.authConfig.id && this.state.loginFromPostRequest && this.props.login && !this.props.login.error) {
            
            //validate when login is not loaded, and when login has not completed
            if (this.props.login && !this.props.login.loading) {
                if (this.props.authConfig.lastNameLogin) {
                    // lastName login
                    this.props.validateAccountInfo(this.state.accountFromPostRequest, this.state.lastnameFromPostRequest, authConfig.token)
                } else {
                    // password login
                    this.props.validateAccount(this.state.accountFromPostRequest, this.state.epasswordFromPostRequest, authConfig.token)
                }
            }
            // show spinner when login is still loading (validating)
            return(
                <Container>
                    <LargeSpinner/>
                </Container>
            );
        }

        ///////////////
        //login form
        ///////////////

        const account = (this.props.authCode && this.props.authCode.account) ? this.props.authCode.account :
            (this.props.ssoToken && this.props.ssoToken.account) ? this.props.ssoToken.account :
            "";
        const submitButtonText = (authConfig.loginButtonText === "Login") ? t('Login') : t('Connect');

        return (
            <Container>
                <Title>{authConfig[translate("loginHeadlineText", i18n.language)]}</Title>

                {this.props.authConfig.error && 
                    <StyledErrorMessage>{this.props.authConfig.error}</StyledErrorMessage>
                }

                {
                //authCode and ssoToken decryption can fail silently because the user didn't initiate them and can fall back to manual login
                // {this.props.authCode.error && 
                //     <StyledErrorMessage>{this.props.authCode.error}</StyledErrorMessage>
                // }

                // {this.props.ssoToken.error && 
                //     <StyledErrorMessage>{this.props.ssoToken.error}</StyledErrorMessage>
                // }
                }

                {//password login
                    !authConfig.lastNameLogin &&
                    <Formik
                        initialValues={{ account: account, password: '' }}
                        validationSchema={passwordLoginSchema}
                        onSubmit={ (values, actions) => {
                            let encryptedPassword = encrypt(values.password);
                            this.props.validateAccount(values.account, encryptedPassword, authConfig.token)
                        } }
                        enableReinitialize
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
                        <form onSubmit={handleSubmit}>
                            <Field name="account" id="account" type="text" label={t('MileagePlus Account')} 
                                value={values.account} errors={errors} touched={touched} 
                                handleChange={handleChange} handleBlur={handleBlur} tabindex="1"
                                autoFocus="true" autocomplete="username"
                            />
                            {authConfig.showForgotMpLink &&
                                <Subscript><a href="http://mileageplus.com/forgotmp" target="_blank">{t('Forgot account?')}</a></Subscript>
                            }
                            
                            <StyledFieldWrapper
                                name="password"
                                id="password"
                                label={t('Password')}
                                errors={errors}
                                touched={touched} 
                            >
                                <PasswordInput
                                    name="password"
                                    id="password"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.password}
                                    tabIndex="2"
                                />
                            </StyledFieldWrapper>
                            {authConfig.showForgotPasswordLink &&
                                <Subscript><Link to={{ pathname: "/passwordRecovery" }}>{t('Forgot password?')}</Link></Subscript>
                            }
                            
                            {this.props.login.error && 
                                <StyledErrorMessage>{this.props.login.error}</StyledErrorMessage>
                            }
                            <Button type="submit" loading={this.props.login.loading}>
                                {submitButtonText}
                            </Button>
                            
                        </form>
                        )}
                    </Formik>
                }

                {//last name login
                    authConfig.lastNameLogin &&
                    <Formik
                        initialValues={{ account: account, lastName: '' }}
                        onSubmit={ (values, actions) => {
                            this.props.validateAccountInfo(values.account, values.lastName, authConfig.token)
                        } }
                        validationSchema={lastNameLoginSchema}
                        enableReinitialize
                    >
                        {({ values, errors, touched, handleChange, handleBlur }) => (
                        <Form>
                            <Field name="account" id="account" type="text" label={t('MileagePlus Account')} 
                                value={values.account} errors={errors} touched={touched} 
                                handleChange={handleChange} handleBlur={handleBlur} tabindex="1"
                                autoFocus="true" autocomplete="username"
                            />
                            {authConfig.showForgotMpLink &&
                                <Subscript><a href="http://mileageplus.com/forgotmp" target="_blank">{t('Forgot account?')}</a></Subscript>
                            }
                            <StyledField type="text" name="lastName" id="lastName" label={t('Last Name')} value={values.lastName} errors={errors} touched={touched} tabindex="2"/>
                            
                            {this.props.login.error &&
                                <StyledErrorMessage>{this.props.login.error}</StyledErrorMessage>
                            }
                            <Button type="submit" disabled={this.props.login.loading}>{submitButtonText}</Button>
                        </Form>
                        )}
                    </Formik>
                }

                {authConfig.showEnrollmentLink &&
                    <EnrollmentLinkDiv>
                        <EnrollmentLinkMessage>{t('Not a MileagePlus member?')}</EnrollmentLinkMessage>
                        <a href="https://mpenroll.united.com/enroll/liteenroll?utm_source=LW01&utm_medium=liteenroll&utm_campaign=partner" target="_blank">
                            {t('Enroll in MileagePlus')}
                        </a>
                    </EnrollmentLinkDiv>
                }
                
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        authConfig: state.Store.authConfig,
        login: state.Store.login,
        authCode: state.Store.authCode,
        ssoToken: state.Store.ssoToken,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAuthConfig: (cid) => {dispatch(getAuthConfig(cid, "login"))},
        validateAccount: (account, encryptedPassword, authConfigToken) => {dispatch(validateAccount(account, encryptedPassword, authConfigToken))},
        validateAccountInfo: (account, lastName, authConfigToken) => {dispatch(validateAccountInfo(account, lastName, authConfigToken))},
        setAuthConfigError: (error) => {dispatch(setAuthConfigError(error))},
        getAuthCodeData: (authCode, authConfigToken) => {dispatch(getAuthCodeData(authCode, authConfigToken))},
        decryptSsoToken: (ssoToken, authConfigToken) => {dispatch(decryptSsoToken(ssoToken, authConfigToken))},
    };
}

const transLogin = withTranslation('login')(Login);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(transLogin);