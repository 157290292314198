import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        load: 'unspecific', //e.g. en instead of en-US
        whitelist: ['en','es','jp','pt'],
        fallbackLng: 'en',
        // saveMissing: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        ns: [],

        // react i18next special options (optional)
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
        },

        backend: {
            // loadPath: '/locales/{{lng}}/{{ns}}.json',

            // path to post missing resources
            // addPath: 'locales/add/{{lng}}/{{ns}}',
        }
    })

export default i18n
