import React, { Component } from 'react'
import { Progress } from 'antd'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { validatePasswordStrength } from '../actions'

import Field from './styling/Field'
import PasswordInput from './styling/PasswordInput'
import StyledErrorMessage from './styling/ErrorMessage'


export class SetPasswordField extends Component {

    validatePassword(value, t) {
        if(!value){
           return t('Required');
        }

        if(Number.isInteger(this.props.password.passwordStrength)
        && this.props.password.passwordStrength < 2) {
            return this.props.t('Please choose a stronger password');
        }

        return '';
    }

    validateConfirmPassword(value, values, t) {
        let errorMessage = '';

        //confirmation must match
        if(value !== values.password){
            errorMessage = t('Passwords must match');
        }

        return errorMessage;
    }

    componentDidUpdate(prevProps) {
        if(this.props.password !== prevProps.password
            && Number.isInteger(this.props.password.passwordStrength)
            && this.props.password.passwordStrength < 2) {
                this.props.setFieldError('password', this.props.t('Please choose a stronger password'));
        }
    }

    render() {
        const { t } = this.props;

        const passwordStrength = (this.props.password && Number.isInteger(this.props.password.passwordStrength))
            ? 100*(this.props.password.passwordStrength/3) : 0;
        const passwordStrengthColour = (this.props.password && this.props.password.passwordStrength)
            ? ["red", "red", "orange", "limegreen", "limegreen"][this.props.password.passwordStrength] : "red";

        return (
            <div>
                <Field name="password" component={PasswordInput} type="password" label={t('Password')}
                    handleChange={(event) => {
                        this.props.setFieldValue('password', event.target.value);
                        this.props.setFieldTouched('password', true);
                        this.props.validatePasswordStrength(event.target.value);
                    }}
                    handleBlur={(event) => {this.props.setFieldTouched('password', true);}}
                    validate={(value) => this.validatePassword(value, t)}
                    errors={this.props.errors}
                    touched={this.props.touched}
                    required={true}
                    value={this.props.values.password}
                />
                <Field name="confirmPassword" component={PasswordInput} type="password" label={t('Re-type Password')} 
                    validate={(value) => this.validateConfirmPassword(value, this.props.values, t)}
                    handleChange={(event) => {
                        this.props.setFieldValue('confirmPassword', event.target.value);
                        this.props.setFieldTouched('confirmPassword', true);
                    }}
                    handleBlur={(event) => {this.props.setFieldTouched('confirmPassword', true)}}
                    errors={this.props.errors}
                    touched={this.props.touched}
                    required={true}
                    value={this.props.values.confirmPassword}
                />
                <Progress percent={passwordStrength} showInfo={false} strokeColor={passwordStrengthColour} />
                {this.props.password.error && 
                    <StyledErrorMessage>{this.props.password.error}</StyledErrorMessage>
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        password: state.Store.password,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        validatePasswordStrength: (password) => {dispatch(validatePasswordStrength(password))},
    };
}

const transSetPasswordField = withTranslation('setPasswordField')(SetPasswordField);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(transSetPasswordField);