import { Actions } from '../../actions';
import parseError from './parseError';


export function authConfigReducer(state = {}, action) {
    switch(action.type) {
        case Actions.getAuthConfig:
            return {
                ...state,
                loading: true,
                enrollmentPageCount: 0,
                error: '',
            }
        case Actions.getAuthConfigSuccess:
            const getAuthConfigSuccessError = parseError(action);
            if (getAuthConfigSuccessError) {
                return {
                    ...state,
                    loading: false,
                    error: getAuthConfigSuccessError,
                }
            }

            //re-use the last successfully loaded auth config if no CID is provided?
            window.sessionStorage.setItem("cid", action.meta.previousAction.payload.request.params.token);

            let lastEnrollmentPageIndex = 0;
            if(action.payload.data.enrollmentPages
                && Array.isArray(action.payload.data.enrollmentPages)){
                    lastEnrollmentPageIndex = action.payload.data.enrollmentPages.length - 1;
            }
            return {
                ...state,
                loading: false,
                ...action.payload.data,
                lastEnrollmentPageIndex: lastEnrollmentPageIndex,
                error: '',
            }
        case Actions.getAuthConfigFail:
            return {
                ...state,
                loading: false,
                error: 'Failed to retrieve configuration'
            }

        case Actions.setAuthConfigError:
            return {
                ...state,
                error: action.payload.error
            }

        default:
            return state
    }
}

export default authConfigReducer