import React , { Component } from 'react'
import styled from 'styled-components'
import Spinner from '../Spinner'


class Button extends Component {
    render() {
        const { disabled, loading, className, children, ...others } = this.props;
        const inactive = disabled || loading;

        return(
            <button className={className} disabled={inactive} {...others} >
                <Spinner loading={loading}>
                    {children}
                </Spinner>
            </button>
        )
    }
}

const StyledButton = styled(Button)`
    height: 45px;
    width: 215px;
    margin: 40px 10px 15px 0px;
    background-color: ${props => props.disabled || props.loading ? "#9386b9" : "#6244BB"};
    color: ${props => props.disabled || props.loading ? "#FFFFFF" : "#FFFFFF"};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    @media (min-height: 700px){
        margin: 40px 10px 40px 0px;
    }
`;

export default StyledButton