import styled from 'styled-components'

const ButtonBox = styled.div`
    
    &:after {
        content: "";
        display: table;
        clear: both;
    }
`

export default ButtonBox