import styled from 'styled-components'

const Content = styled.div`
    width: 100%;
    max-width:544px;
    margin: 0 auto;
    padding: 15px;
    clear: both;

    @media only screen and (min-width: 375px) {
        padding: 40px;
    }
`;

export default Content