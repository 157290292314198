import React, { Component } from 'react'
import Container from './Container'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

export const StyledLink = styled(Link)`
    height: 45px;
    width: 272px;
    padding: 10px 60px;
    background-color: #6244BB;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
`;

const StyledMessage = styled.div`
    margin-top: 150px;
    margin-bottom: 100px;
    font-weight: bold;
`
const StyledWrapper = styled.div`
    margin: 50px 0;
`


export class Terminate extends Component {
    render() {
        const { t } = this.props;
        let message = this.props.location.state.message || "";

        return (
            <Container>
                <StyledMessage>{message}</StyledMessage>
                {this.props.location.state.returnToLogin &&
                    <StyledWrapper><StyledLink to="/login">{t('Return to Login')}</StyledLink></StyledWrapper>
                }
            </Container>
        );
    }
}

export default withTranslation('terminate')(Terminate)