import React, { Component } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const SpinnerContainer = styled.div`
    text-align: center;
    padding: 50px;
`;

class LargeSpinner extends Component {
    render(){
        return(
            <SpinnerContainer>
                <Spin size="large" />
            </SpinnerContainer>
        )
    }
}

export default LargeSpinner