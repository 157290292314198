import React, {Component} from 'react'
import { connect } from 'react-redux';
import queryString from 'query-string'
import { Redirect } from 'react-router'
import { Formik } from 'formik'
import { DatePicker, Checkbox, Select, Radio } from 'antd'
import { getAuthConfig, setAuthConfigError, enroll } from '../actions'
import moment from 'moment'
import Recaptcha from 'react-google-recaptcha'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import ReactGA from 'react-ga4'

import { translate } from '../functions/translate'

import Container from './Container'
import Field from './styling/Field'
import Button from './styling/Button'
import Title from './styling/Title'
import SetPasswordField from './SetPasswordField'
import SetSecurityQuestionsField from './SetSecurityQuestionsField'
import StyledErrorMessage from './styling/ErrorMessage'
import ButtonBox from './styling/ButtonBox'


export const StyledButton = styled(Button)`
    float: right;
`

const dateFormat = 'MM/DD/YYYY';


function addUrlQueryParam(baseUrl, paramName, paramValue){
    if(!baseUrl){
        return baseUrl;
    } else if(baseUrl.endsWith("?")){
        return baseUrl + paramName + "=" + paramValue;
    } else if (baseUrl.includes("?")) {
        return baseUrl + "&" + paramName + "=" + paramValue;
    } else {
        return baseUrl + "?" + paramName + "=" + paramValue;
    }
}

export class Enroll extends Component {
    constructor(props) {
        super(props);

        const queryParams = queryString.parse(this.props.location.search);
        var cid = queryParams["CID"];

        this.state = {
            currentPage: 0,
            isCaptchaValid: false,
            cid: cid,
        }
    }

    componentDidMount() {
        const { cid } = this.state;
        //get auth config
        if(!this.props.authConfig.id){
            if(cid){
                this.props.getAuthConfig(cid);
            } else if(window.sessionStorage.getItem("cid")) {
                //re-use the last successfully loaded auth config if no CID is provided
                this.props.getAuthConfig(window.sessionStorage.getItem("cid"));
            } else {
                this.props.setAuthConfigError("Partner configuration cannot be found");
            }
        }
    }

    componentDidUpdate(prevProps) {
        //set ga partner dimension
        if(
            this.props.authConfig
            && this.props.authConfig.partnerName
            && this.props.authConfig.partnerName !== prevProps.authConfig.partnerName
        ) {
            ReactGA.set({'dimension1': this.props.authConfig.partnerName});
        }
    }

    nextPage = (values) => {
        //validate page form before changing pages
        this.setState(state => {
            return {
                currentPage: Math.min(state.currentPage + 1, this.props.authConfig.lastEnrollmentPageIndex),
            };
        });
    }

    previousPage = (values) => {
        this.setState(state => ({
            currentPage: Math.max(state.currentPage - 1, 0)
        }));
    }

    handleSubmit = (values, actions) => {
        const isLastPage = (this.state.currentPage === this.props.authConfig.lastEnrollmentPageIndex);
        if(isLastPage){
            this.props.enrollAccount(
                this.props.authConfig.enrollmentPages,
                values,
                this.props.authConfig.partnerCode,
                this.props.authConfig.enrollSource,
                this.props.authConfig.token,
            );
            actions.setSubmitting(false);
        } else {
            actions.setTouched({});
            actions.setSubmitting(false);
            this.nextPage(values);
        }
    }

    handleCaptchaSuccess = () => {
        this.setState({
            isCaptchaValid: true
        });
    }

    handleCaptchaExpire = () => {
        this.setState({
            isCaptchaValid: false
        });
    }

    render() {
        const { t, i18n } = this.props;

        //enroll completed
        if(this.props.enroll && this.props.enroll.authConfigOnlineAuthCode) {
            return (
                <Redirect to="/enrollConfirm"></Redirect>
            )
        }

        const page = (this.props.authConfig.enrollmentPages && this.props.authConfig.enrollmentPages[this.state.currentPage]) 
            ? this.props.authConfig.enrollmentPages[this.state.currentPage] : {};

        return (
            <Container>
                <Title>{this.props.authConfig[translate("enrollmentHeadlineText",i18n.language)]}</Title>

                {this.props.authConfig.error && 
                    <StyledErrorMessage>{this.props.authConfig.error}</StyledErrorMessage>
                }

                <Formik
                    onSubmit={this.handleSubmit}
                    initialValues={{ addressType: "H", birthDate: moment().subtract(1, 'days').format(dateFormat) }}
                    render={({ values, errors, touched, setFieldValue, setFieldTouched, setFieldError, isSubmitting, handleSubmit }) => {return(
                        
                        <form onSubmit={handleSubmit}>
                            {this.props.authConfig.enrollmentPages &&
                                <EnrollmentPage 
                                    page={page} 
                                    lastEnrollmentPageIndex={this.props.authConfig.lastEnrollmentPageIndex} 
                                    currentPage={this.state.currentPage}
                                    nextPage={this.nextPage}
                                    previousPage={this.previousPage}
                                    handleSubmit={this.handleSubmit}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    setFieldError={setFieldError}
                                    errors={errors}
                                    touched={touched}
                                    key={page.id}
                                    t={t}
                                    i18n={i18n}
                                />
                            }
                        <EnrollRecaptcha
                            currentPage={this.state.currentPage}
                            lastEnrollmentPageIndex={this.props.authConfig.lastEnrollmentPageIndex}
                            handleCaptchaSuccess={this.handleCaptchaSuccess}
                            handleCaptchaExpire={this.handleCaptchaExpire}
                        />
                        {this.props.enroll.error && 
                            <StyledErrorMessage>{this.props.enroll.error}</StyledErrorMessage>
                        }
                        <ButtonBox>
                            <StyledErrorMessage>{this.props.enroll.error}</StyledErrorMessage>
                            <NextPageButton currentPage={this.state.currentPage} 
                                    lastEnrollmentPageIndex={this.props.authConfig.lastEnrollmentPageIndex} 
                                    nextPage={this.nextPage} handleSubmit={handleSubmit} 
                                    isSubmitting={isSubmitting} 
                                    isCaptchaValid={this.state.isCaptchaValid}
                                    t={t}
                                    errors={errors} 
                                    touched={touched}
                                    enrollError={this.props.enroll.error}
                                    loading={this.props.enroll.loading}
                                />    
                            <PreviousPageButton currentPage={this.state.currentPage} previousPage={this.previousPage} t={t}/>
                        </ButtonBox>
                    </form>)}}
                 />

            </Container>
        )
    }
}

export class EnrollmentPage extends Component{
    createValidator(field) {
        /*
        OTHER VALIDATION RULES:
        - birth date > in the past
        - phone number > format, length(7 or 10), numeric, drop all non numeric characters (spaces, dashes, paren)
        - email > format, valid email (email field type mostly takes care of this but only on submit)
        - home airport > valid code? length
        - postal code > valid for selected country? length? format for selected country?
        - state > dropdown populated based on country?
        - state and postal code required for US/CA, state required for GB (Widgets.UserDataUtils)
        - EITHER home phone OR business phone
        */

        if(field.type === 'email' && field.required){
            return function(value) {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
                return (!value) ? 'Required' :
                    !emailRegex.test(value) ? 'Invalid email address'
                    : '';
            }
        }

        if(field.type === 'email'){
            return function(value) {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
                return (value && !emailRegex.test(value)) ? 'Invalid email address'
                    : '';
            }
        }

        if(field.type === 'tel' && field.required){
            return function(value) {
                const numbersOnly = value.replace('/\D/g', '');
                return (!value) ? 'Required'
                    : (numbersOnly.length < 7) ? 'Invalid phone number'
                    : undefined;
            }
        }

        if(field.type === 'tel'){
            return function(value) {
                const numbersOnly = value.replace('/\D/g', '');
                return (value && numbersOnly.length < 7) ? 'Invalid phone number'
                    : undefined;
            }
        }

        if(field.required){
            return function(value){
                return ( (!value) ? 'Required' : undefined );
            };
        }

        //no validation necessary
        else {
            return function(value) {return undefined;};
        }
    }
    
    render(){
        const {page, values, setFieldValue, setFieldTouched, setFieldError, t, i18n, errors, touched} = this.props;
        return(
            
            <div>
                <h3>{page[translate("header", i18n.language)]}</h3>
                {
                    page.enrollmentPageFields.map((field, id) => {
                        if (typeof values[field.fieldName] === 'undefined') {
                            values[field.fieldName] = '';
                        }
                        if (field.fieldName === 'password') {
                            return (
                                <SetPasswordField setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} setFieldError={setFieldError} values={values} key='password' t={t} i18n={i18n} errors={errors} touched={touched} validate={this.createValidator(field)}/>
                            );
                        } else if (field.fieldName === 'questions') {
                            return(
                                <SetSecurityQuestionsField setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} key='questions' errors={errors} touched={touched}/>
                            );
                        } else {
                            if(field.type === 'select'){
                                return (
                                    <Field name={field.fieldName} 
                                        value={values[field.fieldName]}
                                        component={Select} type={field.type}
                                        required={field.required} label={field[translate("label", i18n.language)]}
                                        validate={this.createValidator(field)}
                                        handleChange={(value) => {
                                            setFieldValue(field.fieldName, value);
                                            setFieldTouched(field.fieldName, true);
                                        }}
                                        handleBlur={(event) => { setFieldTouched(field.fieldName, true) }}
                                        key={field.fieldName}
                                        //showSearch
                                        //filterOption={(function(){return (input, options) => {return field.options.toLowerCase().indexOf(input.toLowerCase()) >= 0;}})()}
                                        errors={errors} 
                                        touched={touched}
                                    >
                                        <Select.Option value='' key=''></Select.Option>
                                        {
                                            field.options.map((option) => {
                                                return(
                                                    <Select.Option value={option.value} key={option.value}>{option[translate("label", i18n.language)]}</Select.Option>
                                                );
                                            })
                                        }
                                    </Field>
                                );
                            } else if(field.type === 'date') {
                                function disabledDate(date) {
                                    const yesterday = moment().subtract(1, 'days');
                                    if(!date){
                                        return false;
                                    }
                                    return (date > yesterday);
                                }
                                
                                let fieldValue = moment(values[field.fieldName], dateFormat);
                                if(isNaN(fieldValue)){
                                    fieldValue = moment();
                                }
                                return(
                                    <Field name={field.fieldName} 
                                        value={fieldValue}
                                        component={DatePicker}
                                        type={field.type} 
                                        required={field.required} 
                                        label={field[translate("label", i18n.language)]}
                                        validate={this.createValidator(field)}
                                        handleChange={(date, dateString) => {
                                            setFieldValue(field.fieldName, dateString);
                                            setFieldTouched(field.fieldName, true);
                                        }}
                                        handleBlur={(event) => {setFieldTouched(field.fieldName, true)}}
                                        key={field.fieldName}
                                        format={dateFormat}
                                        disabledDate={disabledDate}
                                        showToday={false}
                                        errors={errors} 
                                        touched={touched}
                                    />
                                );
                            } else if(field.type === 'checkbox') {
                                if(values[field.fieldName] === ''){
                                    values[field.fieldName] = false;
                                }
                                return(
                                    <div key={field.fieldName}>
                                        <Field name={field.fieldName} value={values[field.fieldName]} 
                                            component={Checkbox}
                                            type={field.type} required={field.required} label={field[translate("label", i18n.language)]}
                                            validate={this.createValidator(field)}
                                            handleChange={(event) => {
                                                setFieldValue(field.fieldName, event.target.checked); 
                                                setFieldTouched(field.fieldName, true);
                                            }}
                                            handleBlur={(event) => {setFieldTouched(field.fieldName, true)}}
                                            checked={values[field.fieldName]}
                                            errors={errors} 
                                            touched={touched}
                                        />
                                    </div>
                                );
                            } else if(field.type === 'radio') {
                                return(
                                    <div key={field.fieldName}>
                                        <Field name={field.fieldName} 
                                            value={values[field.fieldName]}
                                            component={Radio.Group} type={field.type}
                                            required={field.required} label={field[translate("label", i18n.language)]}
                                            validate={this.createValidator(field)}
                                            handleChange={(value) => {
                                                setFieldValue(field.fieldName, value.target.value);
                                                setFieldTouched(field.fieldName, true);
                                            }}
                                            handleBlur={(event) => { setFieldTouched(field.fieldName, true) }}
                                            key={field.fieldName}
                                            errors={errors} 
                                            touched={touched}
                                        >
                                            {
                                                field.options.map((option) => {
                                                    return(
                                                        <Radio value={option.value} key={option.value}>{option[translate("label", i18n.language)]}</Radio>
                                                    );
                                                })
                                            }
                                        </Field>
                                    </div>
                                );
                            } else {
                                return(
                                    <div key={field.fieldName}>
                                        <Field 
                                            name={field.fieldName} 
                                            value={values[field.fieldName]}
                                            component='input'
                                            type={field.type} required={field.required} 
                                            label={field[translate("label", i18n.language)]}
                                            validate={this.createValidator(field)}
                                            handleChange={(event) => {
                                                setFieldValue(field.fieldName, event.target.value); 
                                                setFieldTouched(field.fieldName, true);
                                            }}
                                            handleBlur={(event) => {setFieldTouched(field.fieldName, true)}}
                                            errors={errors} 
                                            touched={touched}
                                        />
                                    </div>
                                );
                            }
                        }
                    })
                }
            </div>

                    
        )
    }
}

export class EnrollRecaptcha extends Component {
    render(){
        if(this.props.currentPage === this.props.lastEnrollmentPageIndex){
            return (
                <Recaptcha 
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={this.props.handleCaptchaSuccess}
                    onExpired={this.props.handleCaptchaExpire}
                />
            );
        } else {
            return(null);
        }
    }
}

export class NextPageButton extends Component {
    render() {
        if(this.props.currentPage === this.props.lastEnrollmentPageIndex){
            return (
                <StyledButton type='submit' disabled={this.props.loading || !this.props.isCaptchaValid} loading={this.props.loading}>{this.props.t('Submit')}</StyledButton>
            );
        } else {
            return(
                <StyledButton type='submit' disabled={this.props.loading} loading={this.props.loading}>{this.props.t('Next')}</StyledButton>
            );
        }
    }
}

export class PreviousPageButton extends Component {
    render() {
        if(this.props.currentPage !== 0){
            return (
                <StyledButton type='button' onClick={this.props.previousPage}>{this.props.t('Previous')}</StyledButton>
            );
        } else {
            return (null);
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        authConfig: state.Store.authConfig,
        enroll: state.Store.enroll,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getAuthConfig: (cid) => {dispatch(getAuthConfig(cid, "enroll"))},
        setAuthConfigError: (message) => {dispatch(setAuthConfigError(message))},
        enrollAccount: (pages, values, partnerCode, enrollSource, authConfigToken) => {dispatch(enroll(pages, values, partnerCode, enrollSource, authConfigToken))},
    };
}

const transEnroll = withTranslation('enroll')(Enroll);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(transEnroll);