import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { setSecurityQuestions, removeAccountItemFromUpdateList } from '../../actions'

import Container from '../Container'
import SetSecurityQuestionsField from '../SetSecurityQuestionsField'

import Title from '../styling/Title'
import Button from '../styling/Button'


export class UpdateSecurityQuestions extends Component {
   render() {
      const { t } = this.props;
      if(this.props.securityQuestionsUpdateSuccessful){
         this.props.removeAccountItemFromUpdateList('questions');
         window.newrelic.interaction().save();
         return (<Redirect to='/login' />);
      }

      //no authCode, return to login
      if(!this.props.sidOnlineAuthCode){
         this.props.removeAccountItemFromUpdateList("questions");
         window.newrelic.interaction().save();
         return(<Redirect to="/login" />);
      }

      return (
         <Container>
            <Title>{t('Update Security Questions')}</Title>
            <Formik
               initialValues={{ }}
               onSubmit={ (values, actions) => this.props.setSecurityQuestions(this.props.sidOnlineAuthCode, values) }
            >
               {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
               <Form>
                  <SetSecurityQuestionsField setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} key='questions' errors={errors} touched={touched} />
                  <Button type="submit" loading={this.props.loading}>{t('Submit')}</Button>
               </Form>
               )}
            </Formik>
         </Container>
      )
   }
}

const mapStateToProps = (state, ownProps) => {
   return {
      securityQuestionsUpdateSuccessful: state.Store.securityQuestions.securityQuestionsUpdateSuccessful,
      sidOnlineAuthCode: state.Store.login.sidOnlineAuthCode,
      offlineAuthCode: state.Store.login.offlineAuthCode,
      loading: state.Store.securityQuestions.loading,
   };
}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      setSecurityQuestions: (sidOnlineAuthCode, values) => { dispatch(setSecurityQuestions(sidOnlineAuthCode, values)) },
      removeAccountItemFromUpdateList: (accountItem) => {dispatch(removeAccountItemFromUpdateList(accountItem))},
   };
}

const trans = withTranslation('updateSecurityQuestions')(UpdateSecurityQuestions);
export default connect(
   mapStateToProps,
   mapDispatchToProps
)(trans)