import styled from 'styled-components'

const H2 = styled.h2`
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #003057;
`;

export default H2