import React, {Component} from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import OutboundLink from '../styling/OutboundLink'


const StyledFooter = styled.div`
   display: block;
   height: 50px;
   margin-top: 5px;
   font-size: 12px;

   @media (min-height: 700px){
       display: block;
       margin-top: 25px;
       font-size: inherit;
   }
`

const ProgramRulesDiv = styled.div`
    margin-bottom: 5px;

    @media (min-height: 700px){
        margin-bottom: 20px;
    }
`

export class Footer extends Component {
    render() { 
        const { t } = this.props;
        return (
            <StyledFooter>
                { this.props.authConfig
                    && <ProgramRulesDiv>
                        <a href={this.props.authConfig.mileageplusRulesUrl || "https://www.united.com/en/us/fly/mileageplus.html"} target="_blank">
                            {t('MileagePlus program rules')}
                        </a>
                    </ProgramRulesDiv>
                }

                <div>{t("By continuing, you consent to the use of your information as described in United's ")}<a href="http://mileageplus.com/privacypolicy" target="_blank">{t('privacy policy')}</a></div>
            </StyledFooter>
        );
    }
}

function mapStateToProps(state={}, ownProps){
    return {
        authConfig: state.Store.authConfig,
    };
}


const translatedFooter = withTranslation('footer')(Footer);
export default connect(
    mapStateToProps,
)(translatedFooter);