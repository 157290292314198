import { Actions } from '../../actions';
import parseError from './parseError';


export function passwordReducer(state={}, action) {
    switch(action.type){
        //disconnect validatePasswordStrenght from loading and error. Nobody really cares
        case Actions.validatePasswordStrength:
            return {
                ...state,
                // loading: true,
            }
        case Actions.validatePasswordStrengthSuccess:
            const validatePasswordStrengthSuccessError = parseError(action);
            if (validatePasswordStrengthSuccessError 
                || !Number.isInteger(action.payload.data.passwordStrength)
            ) {
                return {
                    ...state,
                    // loading: false,
                    passwordStrength: 0,
                    // error: validatePasswordStrengthSuccessError || "Failed to validate password strength",
                }
            }

            const passwordStrength = Number.isInteger(action.payload.data.passwordStrength) ? action.payload.data.passwordStrength + 1 : 0;
            return {
                ...state,
                // loading: false,
                passwordStrength: passwordStrength,
            }
        case Actions.validatePasswordStrengthFail:
            return {
                ...state,
                // loading: false,
            }

        case Actions.updatePassword:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.updatePasswordSuccess:
            const updatePasswordSuccessError = parseError(action);
            if (updatePasswordSuccessError || !action.payload.data.result) {
                return {
                    ...state,
                    loading: false,
                    updatePasswordSuccess: false,
                    error: updatePasswordSuccessError || "Failed to update password",
                }
            }

            return {
                ...state,
                loading: false,
                error: "",
                updatePasswordSuccess: (action.payload.data.result === 1)
            }
        case Actions.updatePasswordFail:
            return {
                ...state,
                loading: false,
                error: "Failed to update password",
                updatePasswordSuccess: false,
            }

        default:
            return state
    }
}

export default passwordReducer