import React, { Component } from 'react';
import styled from 'styled-components';


class ErrorMessage extends Component {
    render(){
        if(this.props.children){
            return (
                <div className={this.props.className}>
                    {this.props.children}
                </div>
            );
        } else {
            return(null);
        } 
    }
}

const StyledErrorMessage = styled(ErrorMessage)`
    color: red;
    margin: 0;
    font-size: 12px;

    @media (min-height: 700px){
        margin: 10px 0;
        font-size: inherit;
    }
`;

export default StyledErrorMessage;
