import React, { Component } from 'react'
import { Formik } from 'formik'
import { Tooltip, Checkbox } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import styled from 'styled-components'

import { sendForgotAnswersEmail } from '../actions'

import Container from './Container'
import Title from './styling/Title'
import Field from './styling/Field'
import Button from './styling/Button'
import Link from './styling/Link'
import Description from './styling/Description'
import LargeSpinner from './styling/LargeSpinner'
import StyledErrorMessage from './styling/ErrorMessage'

import tooltipIcon from '../images/whats_this.svg'


const TooltipIcon = styled.img`
    height: 20px;
    width: 20px;
    margin-bottom: 3px;
`

const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #666666;
        border-color: #666666;
    }
`

const AddDeviceDiv = styled.div`
    margin-top: 40px;
`

const ForgotAnswersDiv = styled.div`
    margin-top: 30px;
`

export class SecurityQuestions extends Component {
    //This function takes the array of questions and answers from the security questions
    //form, and calls processAuthQuestions with either one or two named question
    //variables as appropriate
    preProcessAuthQuestions(questions=[], formData){
        const addDeviceAuthentication = formData.addDeviceAuthentication;

        // call process auth questions with authConfigToken and sid auth codes for login to get authConfig auth codes
        // call process auth questions without to get sid auth codes for password recovery

        const formCopy = Object.assign({},formData);
        delete formCopy.addDeviceAuthentication;

        if(questions.length < 1){
            console.log(this.props.t('Invalid security question responses'));
            return;
        }

        const question1 = questions[0].QuestionKey;
        const answer1 = formCopy[question1];

        if(questions.length > 1){
            const question2 = questions[1].QuestionKey;
            const answer2 = formCopy[question2];

            this.props.processAuthQuestions(
                this.props.login.account, this.props.sessionToken, this.props.isPasswordRecovery, addDeviceAuthentication,
                this.props.authConfig.token, this.props.login.sidOnlineAuthCode,
                question1, answer1,
                question2, answer2
            );
            return;
        } else {
            this.props.processAuthQuestions(
                this.props.login.account, this.props.sessionToken, this.props.isPasswordRecovery, addDeviceAuthentication,
                this.props.authConfig.token, this.props.login.sidOnlineAuthCode,
                question1, answer1
            );
        }
    }

    render() {
        const { t } = this.props;
        let questions = Array.isArray(this.props.securityQuestions) ? this.props.securityQuestions : [];

        if(questions.length === 0) {
            if(this.props.error) {
                return (
                    <Container>
                        <StyledErrorMessage>{this.props.error}</StyledErrorMessage>
                    </Container>
                );
            } else {
                return(
                    <Container>
                        <LargeSpinner/>
                    </Container>
                );
            }
        }

        return (
            <Container>
                <Title>{t('Security Questions')}</Title>
                <Description>{t('Please answer your security questions')}</Description>
                <Formik
                    onSubmit={ (values, actions) => {this.preProcessAuthQuestions(questions, values)} }
                    initialValues={{addDeviceAuthentication:false}}
                    enableReinitialize={true}
                >
                {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                        {
                            //one select input per question
                            questions.map(function(question){
                                let answers = Array.isArray(question.Answers)?question.Answers:[];
                                return (
                                    <div key={question.QuestionKey}>
                                        <Field name={question.QuestionKey} component='select' label={question.QuestionText} key={question.QuestionKey}>
                                            <option value={""} key={"emptyKey"}></option>
                                            {
                                                //one option per answer for each question
                                                answers.map(function(answer){
                                                    return (
                                                        <option value={answer.AnswerKey} key={question.QuestionsKey + answer.AnswerKey}>
                                                            {answer.AnswerText}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </Field>
                                    </div>
                                );
                            })
                        }
                        
                        { !this.props.isPasswordRecovery && this.props.addDeviceAuthenticationAvailability &&
                            <AddDeviceDiv>
                                <label>
                                    <StyledCheckbox name="addDeviceAuthentication" checked={values.addDeviceAuthentication} onChange={handleChange} onBlur={handleBlur}/>    
                                    <span>{t('Remember me on this device?')}</span>
                                    <Tooltip title={t("Avoid having to answer your security questions in the future")}>
                                        <TooltipIcon src={tooltipIcon} alt="tool tip" />
                                    </Tooltip>
                                </label>
                            </AddDeviceDiv>
                        }

                        <ForgotAnswersDiv>
                            <Link to={{ pathname: "/terminate", state: { message: t('Forgot answers email sent'), returnToLogin: true} }}
                                onClick={() => {this.props.sendForgotAnswersEmail(this.props.login.account)}}
                            >
                                {t('Forgot your answers?')}
                            </Link>
                        </ForgotAnswersDiv>

                        {this.props.error && 
                            <StyledErrorMessage>{this.props.error}</StyledErrorMessage>
                        }
                        <Button type="submit" loading={this.props.loading}>{t('Submit')}</Button>
                    </form>
                )}
                </Formik>
            </Container>
        )
    }
}

//TODO: does securityQuestions need to be connected to the store or can it receive all it's data from it's parents? Or should it receive most of its data from the store and less from its parents?
function mapStateToProps(state, ownProps) {
    return{
        ...state,
        authConfig: state.Store.authConfig,
        login: state.Store.login,
        loading: state.Store.securityQuestions.loading,
        error: state.Store.securityQuestions.error,
    };
}

function mapDispatchToProps(dispatch, actions) {
    return{
        sendForgotAnswersEmail: (account) => {dispatch(sendForgotAnswersEmail(account))}
    };
}

const trans = withTranslation('securityQuestions')(SecurityQuestions);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(trans)