import { Actions } from '../../actions';
import parseError from './parseError';


export function loginReducer(state = {}, action) {
    switch(action.type) {
        case Actions.validateAccount:
            return {
                ...state,
                loading: true,
                error: '',
                account: action.payload.request.params.account,
            }
        case Actions.validateAccountSuccess:
            const validateAccountSuccessError = parseError(action);
            if (validateAccountSuccessError) {
                return {
                    ...state,
                    loading: false,
                    error: "Login failed",
                }
            }

            //if deviceAuthenticated !== null && !deviceAuthenticated > two factor
            const deviceAuthenticated = (action.payload.data.deviceAuthenticated === "true")
            const addDeviceAuthenticationAvailability = (action.payload.data.addDeviceAuthenticationAvailability === "true")

            const accountItemsToUpdate = (action.payload.data.accountItemsToUpdate ? action.payload.data.accountItemsToUpdate : [])

            //else redirect to redirectURl?onlineAuthCode&offlineAuthCode
            return {
                ...state,
                loading: false,
                error: '',
                sidOnlineAuthCode: action.payload.data.onlineAuthCode,
                sidOfflineAuthCode: action.payload.data.offlineAuthCode,
                authConfigOnlineAuthCode: action.payload.data.authConfigOnlineAuthCode,
                authConfigOfflineAuthCode: action.payload.data.authConfigOfflineAuthCode,
                deviceAuthenticated: deviceAuthenticated,
                addDeviceAuthenticationAvailability: addDeviceAuthenticationAvailability,
                accountItemsToUpdate: accountItemsToUpdate,
            }
        case Actions.validateAccountFail:
            return {
                ...state,
                loading: false,
                error: "Login failed",
            }
        
        //validateAccountInfo
        case Actions.validateAccountInfo:
            return {
                ...state,
                loading: true,
                error: '',
                account: action.payload.request.params.accountNumber,
            }
        case Actions.validateAccountInfoSuccess:
            const validateAccountInfoSuccessError = parseError(action);
            if (validateAccountInfoSuccessError) {
                return {
                    ...state,
                    loading: false,
                    error: "Login failed",
                }
            }

            return {
                ...state,
                loading: false,
                error: '',
                //validateAccountInfo doesnt do account updates, doesnt need any sid auth codes
                // onlineAuthCode: action.payload.data.onlineAuthCode,
                // offlineAuthCode: action.payload.data.offlineAuthCode,
                authConfigOnlineAuthCode: action.payload.data.authConfigOnlineAuthCode,
                authConfigOfflineAuthCode: action.payload.data.authConfigOfflineAuthCode,
            }
        case Actions.validateAccountInfoFail:
            return {
                ...state,
                loading: false,
                error: "Login failed",
            }

        //checkPasswordRecoveryEligibility
        case Actions.checkPasswordRecoveryEligibility:
            return {
                ...state,
                loading: true,
                error: '',
            }
        case Actions.checkPasswordRecoveryEligibilitySuccess:
            const checkPasswordRecoveryEligibilitySuccessError = parseError(action);
            if (checkPasswordRecoveryEligibilitySuccessError) {
                return {
                    ...state,
                    loading: false,
                    error: checkPasswordRecoveryEligibilitySuccessError || "Password recovery eligibility check failed",
                    passwordRecoveryEligibilityStatus: "Ineligible",
                    passwordRecoveryEligibilityWarningCode: "",
                    passwordRecoveryEligibilityWarningMessage: "Password recovery eligibility check failed",
                }
            }

            return {
                ...state,
                loading: false,
                error: '',
                passwordRecoveryEligibilityStatus: action.payload.data.passwordRecoveryEligibilityStatus,
                passwordRecoveryEligibilityWarningCode: action.payload.data.passwordRecoveryEligibilityWarningCode,
                passwordRecoveryEligibilityWarningMessage: action.payload.data.passwordRecoveryEligibilityWarningMessage,
            }
        case Actions.checkPasswordRecoveryEligibilityFail:
            return {
                ...state,
                loading: false,
                error: "Check password recovery eligibility failed",
            }

        //processAuthQuestions
        case Actions.processAuthQuestionsSuccess:
            const processAuthQuestionsSuccessError = parseError(action);
            if (processAuthQuestionsSuccessError) {
                return {
                    ...state,
                    //this error should be handled in the securityQuestionReducer
                    //error: processAuthQuestionsSuccessError || "Failed to process auth questions",
                }
            }    
        
            if (action.payload.data.result 
                && (action.payload.data.result.status === "Success" || action.payload.data.result.status === "AllowResetPassword")
            ){
                return {
                    ...state,
                    // sid auth codes for password recovery
                    sidOnlineAuthCode: action.payload.data.onlineAuthCode,
                    sidOfflineAuthCode: action.payload.data.offlineAuthCode,
                    // authConfig auth codes for two factor login
                    authConfigOnlineAuthCode: action.payload.data.authConfigOnlineAuthCode,
                    authConfigOfflineAuthCode: action.payload.data.authConfigOfflineAuthCode,
                }
            }
            return {
                ...state,
            }

        default:
            return state
    }
}

export default loginReducer