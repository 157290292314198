import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import { createLogger } from 'redux-logger'
import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
import { googleAnalytics } from './analytics/reactGAMiddlewares'

import Store from './redux/reducers/reducer'

const rootReducer = combineReducers({
  Store,
})

const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const logger = createLogger()


const axiosClient = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: process.env.REACT_APP_CREW_PARTNER_URL,
  responseType: 'json',
})

axiosClient.interceptors.request.use(request => {
    console.log('Starting Request', request)
    return request
 })

axiosClient.interceptors.response.use(response => {
    console.log('Response:', response)
    return response
})

const middleware = [axiosMiddleware(axiosClient), googleAnalytics, logger]

const store = createStore(
  rootReducer,
  /* preloadedState, */
  composeEnhancers(applyMiddleware(...middleware))
)

export default store
