import { Actions } from '../../actions';
import parseError from './parseError';


export function securityQuestionReducer(state = {}, action) {
    switch(action.type) {

        //getSecurityQuestions
        case Actions.getSecurityQuestions:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.getSecurityQuestionsSuccess:
            const getSecurityQuestionsSuccessError = parseError(action);
            if (getSecurityQuestionsSuccessError 
                || !action.payload.data.questions 
                || !action.payload.data.questions.questions 
                || !Array.isArray(action.payload.data.questions.questions)
            ) {
                return {
                    ...state,
                    loading: false,
                    securityQuestions: [],
                    sessionToken: null,
                    error: getSecurityQuestionsSuccessError || "Unable to retrieve security questions",
                }
            }

            //TODO: handle locked status

            return {
                ...state,
                loading: false,
                error: "",
                securityQuestions: action.payload.data.questions.questions,
                sessionToken: action.payload.data.sessionToken,
                //processAuthQuestionsStatus: null,
            }
        case Actions.getSecurityQuestionsFail:
            return {
                ...state,
                loading: false,
                error: "Unable to retrieve security questions",
            }

        //getAllSecurityQuestions
        case Actions.getAllSecurityQuestions:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.getAllSecurityQuestionsSuccess:
            const getAllSecurityQuestionsSuccessError = parseError(action);
            if (getAllSecurityQuestionsSuccessError 
                || !action.payload.data.questions 
                || !action.payload.data.questions.securityQuestions 
                || !Array.isArray(action.payload.data.questions.securityQuestions)
            ) {
                return {
                    ...state,
                    loading: false,
                    securityQuestions: [],
                    error: getAllSecurityQuestionsSuccessError || "Unable to retrieve security questions",
                }
            }
            
            return {
                ...state,
                loading: false,
                error: "",
                securityQuestions: action.payload.data.questions.securityQuestions
            }
        case Actions.getAllSecurityQuestionsFail:
            return {
                ...state,
                error: "Unable to retrieve security questions",
                loading: false,
            }

        //processAuthQuestions
        case Actions.processAuthQuestions:
            return {
                ...state,
                loading: true,
                error: "",
                addDeviceAuthentication: action.payload.request.params.addDeviceAuthentication,
            }
        case Actions.processAuthQuestionsSuccess:
            const processAuthQuestionsSuccessError = parseError(action);
            if (processAuthQuestionsSuccessError 
                || !action.payload.data.result 
                || !action.payload.data.result.status
                
                //no auth codes
                || (action.payload.data.result.status === "Success" && !action.payload.data.authConfigOnlineAuthCode)
                || (action.payload.data.result.status === "AllowResetPassword" && !action.payload.data.onlineAuthCode)
            ) {
                return {
                    ...state,
                    loading: false,
                    processAuthQuestionsStatus: "Error",
                    error: processAuthQuestionsSuccessError || "Unable to process security question answers",
                }
            }

            return {
                ...state,
                loading: false,
                error: "",
                processAuthQuestionsStatus: action.payload.data.result.status,
                // update login auth codes in login reducer
            }
        case Actions.processAuthQuestionsFail:
            return {
                ...state,
                loading: false,
                error: "Unable to process security question answers",
            }

        //sendForgotAnswersEmail
        case Actions.sendForgotAnswersEmail:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.sendForgotAnswersEmailSuccess:
            const sendForgotAnswersEmailSuccessError = parseError(action);
            if (sendForgotAnswersEmailSuccessError) {
                return {
                    ...state,
                    loading: false,
                    sendForgotAnswersEmailAddress: "",
                    error: sendForgotAnswersEmailSuccessError || "Failed to send forgot answers email",
                }
            }

            return {
                ...state,
                loading: false,
                error: "",
                sendForgotAnswersEmailAddress: action.payload.data.emailAddress
            }
        case Actions.sendForgotAnswersEmailFail:
            return {
                ...state,
                loading: false,
                error: "Failed to send email",
            }

        //setSecurityQuestions
        case Actions.setSecurityQuestions:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.setSecurityQuestionsSuccess:
            const setSecurityQuestionsSuccessError = parseError(action);
            if (setSecurityQuestionsSuccessError) {
                return {
                    ...state,
                    loading: false,
                    securityQuestionsUpdateSuccessful: false,
                    error: setSecurityQuestionsSuccessError || "Failed to set security questions",
                }
            }
        
            return {
                ...state,
                loading: false,
                error: "",
                securityQuestionsUpdateSuccessful: true,
            }
        case Actions.setSecurityQuestionsFail:
            return {
                ...state,
                loading: false,
                error: "Failed to set security questions",
            }

        default:
            return state
    }
}

export default securityQuestionReducer