import { Actions } from '../../actions';
import parseError from './parseError';


export function enrollReducer(state={}, action) {
    switch (action.type) {
        case Actions.enroll:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.enrollSuccess:
            const enrollSuccessError = parseError(action);
            if (enrollSuccessError) {
                return {
                    ...state,
                    loading: false,
                    error: enrollSuccessError || "Enrollment failed",
                }
            }

            return {
                ...state,
                loading: false,
                error: "",
                account: action.payload.data.memberID,
                authConfigOnlineAuthCode: action.payload.data.authConfigOnlineAuthCode,
                authConfigOfflineAuthCode: action.payload.data.authConfigOfflineAuthCode,

            }
        case Actions.enrollFail:
            return {
                ...state,
                loading: false,
                error: "Enrollment failed",
            }

        default:
            return state
    }
}

export default enrollReducer