import React, { Component } from 'react'
import { connect } from 'react-redux'
import Container from './Container'
import { withTranslation } from 'react-i18next'
import { addUrlQueryParam } from '../functions/functions'
import OutboundLinkButton from './styling/OutboundLinkButton'
import H2 from './styling/H2'
import P from './styling/P'



export class EnrollConfirm extends Component {
    render() {
        const { t } = this.props;

        //check for required parameters
        if(
            !this.props.authConfig || !this.props.authConfig.redirectUrl
            ||!this.props.account || !this.props.authConfigOnlineAuthCode
        ){
            console.error('Redirect URL, account or authConfigOnlineAuthCode missing on enrollment confirmation page');
            return(
                <Container>
                    <H2>{t('Enrollment failed')}</H2>
                </Container>
            )
        } else {
            let url = this.props.authConfig.redirectUrl;

            //add auth codes
            url = addUrlQueryParam(url, "onlineAuthCode", this.props.authConfigOnlineAuthCode);
            url = addUrlQueryParam(url, "offlineAuthCode", this.props.authConfigOfflineAuthCode);

            //add enroll params from db
            if(this.props.authConfig && this.props.authConfig.enrollUrlParams){
                url = url + "&" + this.props.authConfig.enrollUrlParams;
            }

            console.log('Enrollment complete');

            return (
                <Container>
                    <H2>{t('Congratulations, you are now enrolled in MileagePlus!')}</H2>
                    <P>{t('Your new MileagePlus account number is')} <b>{this.props.account}</b></P>
                    <P>{t('Please record your number for your future reference.')}</P>
                    <OutboundLinkButton eventLabel="Confirm Enrollment" to={url}>{t('Finish')}</OutboundLinkButton>
                </Container>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        authConfig: state.Store.authConfig,
        account: state.Store.enroll.account,
        authConfigOnlineAuthCode: state.Store.enroll.authConfigOnlineAuthCode,
        authConfigOfflineAuthCode: state.Store.enroll.authConfigOfflineAuthCode,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return{}
}

const transEnrollConfig = withTranslation('enrollConfirm')(EnrollConfirm);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(transEnrollConfig);