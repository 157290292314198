import React, { Component } from 'react'
import { Icon } from 'antd'
import styled from 'styled-components'


const StyledIcon = styled(Icon)`
    font-size: 24;
    color: white;
`

class Spinner extends Component {
    render() {
        return (
            <div>
                {!this.props.loading &&
                    this.props.children
                }
                {this.props.loading &&
                    <StyledIcon type="loading" spin />
                }
            </div>
        );
    }
}

export default Spinner