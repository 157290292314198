import React from 'react'
import styled from 'styled-components';
import { Input } from 'antd'


class PasswordInput extends React.Component {

    render(){
        const { handleChange, handleBlur, value, ...other } = this.props;
        return (
            <Input.Password
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                autoComplete="current-password"
                {...other}
            />
        )
    }
}

const StyledPasswordInput = styled(PasswordInput)`
    .ant-input{
        border-width: 0 0 1px 0;
        border-right: none;
        border-radius: unset;
        border-color: #666666;
        font-size: 16px;
        color: #000000;
        margin: 5px 0;
        padding-left: 0;
    }

    .ant-input:hover {
        border-right: none;
    }

    .ant-input:focus {
        border-right: none;
        box-shadow: none;
        outline: -webkit-focus-ring-color auto 5px;
    }
`


export default StyledPasswordInput;