import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

export const StyledLanguageSelector = styled.div`
  display: none;
  color: #2172ba;
  font-size: 14px;
  text-decoration: none;
  float: right;
  clear: both;

  @media (min-height: 800px) {
    display: flex;
 }
`

export const Button = styled.button`
  background-color: transparent;
  color: #2172ba;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
     text-decoration: underline;
  }
`

export class LanguageSelector extends Component {
//    static contextTypes = {
//      i18n: PropTypes.object,
//    }

    render() {
        const { i18n } = this.props;

        return (
            <StyledLanguageSelector id="LangSelector">
                <Button name="en" onClick={() => i18n.changeLanguage('en')}>
                    English
                </Button>
                |
                <Button name="es" onClick={() => i18n.changeLanguage('es')}>
                    Español
                </Button>
                |
                <Button name="pt" onClick={() => i18n.changeLanguage('pt')}>
                    Português
                </Button>
                |
                <Button name="jp" onClick={() => i18n.changeLanguage('jp')}>
                    日本語
                </Button>
            </StyledLanguageSelector>
        );
    }
}

export default withTranslation()(LanguageSelector)
