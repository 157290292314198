import ReactGA from 'react-ga4'

export const trackEvent = event => {
  const { category, action, label, value, nonInteraction = false } = event
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
    nonInteraction: nonInteraction,
  })
}

export const googleAnalytics = store => next => action => {
  const parts = action.type.split('/')
  const category = `Redux Actions: ${parts[0]}`
  const label = parts[1]

  const event = {
    category: category,
    action: action.type,
    label: label,
    value: 0,
  }

  trackEvent(event)

  return next(action)
}
