import React, { Component } from 'react'
import Container from '../Container'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { checkPasswordRecoveryEligibility } from '../../actions'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import Title from '../styling/Title'
import Form from '../styling/Form'
import Field from '../styling/Field'
import Button from '../styling/Button'


const validationSchema = Yup.object().shape({
   account: Yup.string()
       .required('Required')
       .matches(/^([a-zA-Z0-9]{6,11})$/, 'Invalid MP account number'),
});

export class PasswordRecovery extends Component{

   render() {
      const { t } = this.props;
      if(typeof this.props.passwordRecoveryEligibilityStatus === 'undefined' || this.props.passwordRecoveryEligibilityStatus === ""){
         return(
            <Container>
               <Title>{t('Password Reset')}</Title>
               <Formik
                  initialValues={{account: ""}}
                  onSubmit= {(values, actions) => { this.props.checkPasswordRecoveryEligibility(values.account)}}
                  validationSchema={validationSchema}
               >
                  {({values, touched, errors, handleChange, handleBlur, handleSubmit}) => (
                  <Form>
                     <Field type="text" name="account" label={t('MileagePlus Account')} 
                        value={values.account} errors={errors} touched={touched} 
                        handleChange={handleChange} handleBlur={handleBlur}
                     />
                     <Button type="submit" loading={this.props.loading}>{t('Submit')}</Button>
                  </Form>
                  )}
               </Formik>
            </Container>
         )
      } else if(this.props.passwordRecoveryEligibilityStatus === "Eligible"){
        window.newrelic.interaction().save(); 
        return(
            <Redirect to={{ pathname: "/passwordRecovery/securityQuestions" }} />
         )
      } else {
         const message = this.props.passwordRecoveryEligibilityWarningMessage || "Ineligible for password recovery";
         window.newrelic.interaction().save();
         return(
            <Redirect to={{ pathname: "/terminate", state: {message: message, returnToLogin: true} }} />
         );
      }
   }
}

function mapStateToProps(state, ownProps) {
   return {
      ...state,
      passwordRecoveryEligibilityStatus: state.Store.login.passwordRecoveryEligibilityStatus,
      passwordRecoveryEligibilityWarningMessage: state.Store.login.passwordRecoveryEligibilityWarningMessage,
      loading: state.Store.login.loading,
   }
}

function mapDispatchToProps(dispatch, ownProps) {
   return{
      checkPasswordRecoveryEligibility: (account) => { dispatch(checkPasswordRecoveryEligibility(account))},
   }
}

const trans = withTranslation('passwordRecovery')(PasswordRecovery);
export default connect(
   mapStateToProps,
   mapDispatchToProps,
) (trans);