import React, { Component } from 'react'
import styled from 'styled-components'
import { Field as FormikField } from 'formik'
import { withTranslation } from 'react-i18next'
import FieldWrapper from './FieldWrapper'


export const StyledFormikField = styled(FormikField)`
     margin: 5px 0px;
     border-width: 0 0 1px 0;
     border-color: #666666;
     color: #000000;
`;

export class Field extends Component {

    render() {
        var { name, value, className, required, label, component, type, id, validate, handleChange, handleBlur, children, errors, touched, tabindex, autoFocus, autocomplete, format, disabledDate, showToday } = this.props;
        let props = {
            name: name,
            component: component,
            type:type,
            className: className,
            tabIndex: tabindex,
            autoComplete: autocomplete,
        }

        if(value) { props.value = value };
        if(validate) { props.validate = validate };
        if(handleChange) { props.onChange = handleChange };
        if(handleBlur) { props.onBlur = handleBlur };
        //datepicker
        if(format) { props.format = format };
        if(disabledDate) { props.disabledDate = disabledDate };
        if(showToday !== undefined) { props.showToday = showToday };

        return(
            <FieldWrapper name={name} className={className} required={required} label={label} errors={errors} touched={touched}>
                <StyledFormikField id={id} autofocus={autoFocus} {...props}>
                    {children}
                </StyledFormikField>
            </FieldWrapper>
        )
    }
}

export default withTranslation('field')(Field)
