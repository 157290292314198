import { Actions } from '../../actions';
import parseError from './parseError';


export function profileReducer(state={}, action) {
    switch(action.type) {
        case Actions.getProfile:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.getProfileSuccess:
            const getProfileSuccessError = parseError(action);
            if (getProfileSuccessError) {
                return {
                    ...state,
                    loading: false,
                    error: getProfileSuccessError || "Failed to retrieve profile",
                }
            }
        
            return {
                ...state,
                ...action.payload.data,
                loading: false,
                error: "",
            }
        case Actions.getProfileFail:
            return {
                ...state,
                loading: false,
                error: "Failed to retrieve profile",
            }
        
        case Actions.removeAccountItemFromUpdateList:
            const itemToRemove = action.payload;
            if(state.securityUpdate && state.securityUpdate.accountItemsToUpdate){
                const remainingItemsToUpdate = state.securityUpdate.accountItemsToUpdate.filter(item => item !== itemToRemove);
                return {
                    ...state,
                    securityUpdate: {
                        accountItemsToUpdate: remainingItemsToUpdate
                    }
                }
            } else {
                return state
            }

        case Actions.updateEmail:
            return {
                ...state,
                loading: true,
                error: "",
            }
        case Actions.updateEmailSuccess:
            const updateEmailSuccessError = parseError(action);
            if (updateEmailSuccessError) {
                return {
                    ...state,
                    loading: false,
                    emailUpdateSuccessful: false,
                    error: updateEmailSuccessError || "Failed to update email",
                }
            }
        
            return {
                ...state,
                loading: false,
                error: "",
                emailUpdateSuccessful: true,
            }
        case Actions.updateEmailFail:
            return {
                ...state,
                loading: false,
                error: "Failed to update email",
            }

        default:
            return state
    }
}

export default profileReducer