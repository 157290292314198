import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import { withTranslation } from 'react-i18next'

import { getProfile, updateEmail, removeAccountItemFromUpdateList } from '../../actions'

import Container from '../Container'
import Title from '../styling/Title'
import Field from  '../styling/Field'
import Button from '../styling/Button'
import StyledErrorMessage from '../styling/ErrorMessage'


const updateEmailSchema = Yup.object().shape({
   currentEmail: Yup.string()
      .required('Required')
      .email('Invalid email address'),
   newEmail: Yup.string()
      .required('Required')
      .email('Invalid email address')
});

export class UpdateEmail extends Component {

   componentDidMount() {
      this.props.getProfile(this.props.sidOnlineAuthCode);
   }

   render() {

      //no authCode, return to login
      if(!this.props.sidOnlineAuthCode){
         this.props.removeAccountItemFromUpdateList("verifyemail");
         window.newrelic.interaction().save();
         return(<Redirect to="/login" />);
      }

      //redirect to login after successfully updating
      if(this.props.profile.emailUpdateSuccessful){
         this.props.removeAccountItemFromUpdateList("verifyemail");
         window.newrelic.interaction().save();
         return(<Redirect to="/login" />);
      }

      const currentEmail = (this.props.profile && this.props.profile.profileData && this.props.profile.profileData.emailAddress) 
         ? this.props.profile.profileData.emailAddress
         : '';

      const sidOnlineAuthCode = this.props.sidOnlineAuthCode;

      const { t } = this.props;

      return(
         <Container>
            <Title>{t('Update Email')}</Title>
            {this.props.profile.error && 
               <StyledErrorMessage>{this.props.profile.error}</StyledErrorMessage>
            }
            <Formik
               onSubmit={(values, actions) => {this.props.updateEmail(sidOnlineAuthCode, values.currentEmail, values.newEmail)}}
               initialValues={{ currentEmail: currentEmail, newEmail: '' }}
               validationSchema={updateEmailSchema}
            >
               {({ values, errors, touched }) => (
               <Form>
                  <Field name="currentEmail" label={t('Current Email')} type="email" value={values.currentEmail} errors={errors} touched={touched} />
                  <Field name="newEmail" label={t('New Email')} type="email" value={values.newEmail} errors={errors} touched={touched}/>
                  <Button type="submit" loading={this.props.loading}>{t('Submit')}</Button>
               </Form>
               )}
            </Formik>
         </Container>
      )
   }
}

function mapStateToProps(state, ownProps) {
   return {
      sidOnlineAuthCode: state.Store.login.sidOnlineAuthCode,
      profile: state.Store.profile,
      loading: state.Store.profile.loading,
   };
}

function mapDispatchToProps(dispatch, ownProps) {
   return {
      getProfile: (sidOnlineAuthCode) => {dispatch(getProfile(sidOnlineAuthCode))},
      updateEmail: (sidOnlineAuthCode, currentEmail, newEmail) => {dispatch(updateEmail(sidOnlineAuthCode, currentEmail, newEmail))},
      removeAccountItemFromUpdateList: (accountItem) => {dispatch(removeAccountItemFromUpdateList(accountItem))},
   };
}

const trans = withTranslation('updateEmail')(UpdateEmail);
export default connect(
   mapStateToProps,
   mapDispatchToProps
)(trans)