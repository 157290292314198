import uuid from 'uuid/v1'
import { encrypt } from './functions/functions'

export const Actions = {
    getAuthConfig: 'getAuthConfig',
    getAuthConfigSuccess: 'getAuthConfig_SUCCESS',
    getAuthConfigFail: 'getAuthConfig_FAIL',
    setAuthConfigError: 'setAuthConfigError',
    validateAccount: 'validateAccount',
    validateAccountSuccess: 'validateAccount_SUCCESS',
    validateAccountFail: 'validateAccount_FAIL',
    validateAccountInfo: 'validateAccountInfo',
    validateAccountInfoSuccess: 'validateAccountInfo_SUCCESS',
    validateAccountInfoFail: 'validateAccountInfo_FAIL',
    checkPasswordRecoveryEligibility: 'checkPasswordRecoveryEligibility',
    checkPasswordRecoveryEligibilitySuccess: 'checkPasswordRecoveryEligibility_SUCCESS',
    checkPasswordRecoveryEligibilityFail: 'checkPasswordRecoveryEligibility_FAIL',
    getSecurityQuestions: 'getSecurityQuestions',
    getSecurityQuestionsSuccess: 'getSecurityQuestions_SUCCESS',
    getSecurityQuestionsFail: 'getSecurityQuestions_FAIL',
    processAuthQuestions: 'processAuthQuestions',
    processAuthQuestionsSuccess: 'processAuthQuestions_SUCCESS',
    processAuthQuestionsFail: 'processAuthQuestions_FAIL',
    sendForgotAnswersEmail: 'sendForgotAnswersEmail',
    sendForgotAnswersEmailSuccess: 'sendForgotAnswersEmail_SUCCESS',
    sendForgotAnswersEmailFail: 'sendForgotAnswersEmail_FAIL',
    getAuthCodeData: 'getAuthCodeData',
    getAuthCodeDataSuccess: 'getAuthCodeData_SUCCESS',
    getAuthCodeDataFail: 'getAuthCodeData_FAIL',
    decryptSsoToken: 'decryptSsoToken',
    decryptSsoTokenSuccess: 'decryptSsoToken_SUCCESS',
    decryptSsoTokenFail: 'decryptSsoToken_FAIL',
    enroll: 'enroll',
    enrollSuccess: 'enroll_SUCCESS',
    enrollFail: 'enroll_FAIL',
    getAllSecurityQuestions: 'getAllSecurityQuestions',
    getAllSecurityQuestionsSuccess: 'getAllSecurityQuestions_SUCCESS',
    getAllSecurityQuestionsFail: 'getAllSecurityQuestions_FAIL',
    validatePasswordStrength: 'validatePasswordStrength',
    validatePasswordStrengthSuccess: 'validatePasswordStrength_SUCCESS',
    validatePasswordStrengthFail: 'validatePasswordStrength_FAIL',
    updatePassword: 'updatePassword',
    updatePasswordSuccess: 'updatePassword_SUCCESS',
    updatePasswordFail: 'updatePassword_FAIL',
    getProfile: 'getProfile',
    getProfileSuccess: 'getProfile_SUCCESS',
    getProfileFail: 'getProfile_FAIL',
    removeAccountItemFromUpdateList: 'removeAccountItemFromUpdateList',
    updateEmail: 'updateEmail',
    updateEmailSuccess: 'updateEmail_SUCCESS',
    updateEmailFail: 'updateEmail_FAIL',
    setSecurityQuestions: 'setSecurityQuestions',
    setSecurityQuestionsSuccess: 'setSecurityQuestions_SUCCESS',
    setSecurityQuestionsFail: 'setSecurityQuestions_FAIL',
}

export function getAuthConfig(cid, filter) {
    return {
        type: Actions.getAuthConfig,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_PARTNER_URL + '/authConfig',
                params: {
                    SID: process.env.REACT_APP_SID,
                    token: cid,
                    filter: filter
                }
            }
        }
    }
}

export function setAuthConfigError(error) {
    return {
        type: Actions.setAuthConfigError,
        payload: {
            error: error,
        }
    }
}

export function validateAccount(account, encryptedPassword, authConfigToken) {
    let deviceId = window.localStorage.getItem("deviceId");
    //A deviceId should always be passed
    //If there's no deviceId, we'll assume two factor isn't being done
    if(deviceId === null || deviceId === undefined){
        deviceId = uuid();
        window.localStorage.setItem("deviceId", deviceId);
    }

    //validate account tells you if the device id is valid and if rememberDevice is allowed
    
    return {
        type: Actions.validateAccount,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/validateAccount_v2',
                params: {
                    SID: process.env.REACT_APP_SID,
                    account: account,
                    password: encryptedPassword,
                    deviceId: deviceId,
                    cid: authConfigToken,
                }
            }
        }
    }
}

export function validateAccountInfo(account, lastName, authConfigToken) {
    return {
        type: Actions.validateAccountInfo,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/validateAccountInfo',
                params: {
                    SID: process.env.REACT_APP_SID,
                    accountNumber: account,
                    lastName: lastName,
                    authConfigToken: authConfigToken,
                }
            }
        }
    }
}

export function checkPasswordRecoveryEligibility(account) {
    return{
        type: Actions.checkPasswordRecoveryEligibility,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/checkPasswordRecoveryEligibility',
                params: {
                    SID: process.env.REACT_APP_SID,
                    account: account,
                }
            }
        }
    }
}

export function getSecurityQuestions(account, sessionToken){
    return {
        type: Actions.getSecurityQuestions,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/getSecurityQuestions',
                params: {
                    SID: process.env.REACT_APP_SID,
                    sessionToken: sessionToken,
                    account: account,
                }
            }
        }
    }
}

export function processAuthQuestions(account, sessionToken, isPasswordRecovery, addDeviceAuthentication, 
    authConfigToken, sidOnlineAuthCode, question1, answer1, question2, answer2) {
    const deviceId = window.localStorage.getItem("deviceId");
    const question1Encrypted = encrypt(question1);
    const answer1Encrypted = encrypt(answer1);

    if(isPasswordRecovery) {
        addDeviceAuthentication = false;
    }

    const params = {
        SID: process.env.REACT_APP_SID,
        sessionToken: sessionToken,
        account: account,
        isPasswordRecoveryFlow: isPasswordRecovery,
        addDeviceAuthentication: addDeviceAuthentication,
        deviceId: deviceId,
    }

    // call processAuthQuestions with authConfigToken and a sid authCode to get authConfig auth codes (two factor login)
    // otherwise, you'll get sid auth codes (password recovery)
    if(authConfigToken && sidOnlineAuthCode) {
        params.authConfigToken = authConfigToken;
        params.authenticationCode = sidOnlineAuthCode;
    }

    const body = {
        question1: question1Encrypted,
        answer1: answer1Encrypted
    }
    if(question2){
        const question2Encrypted = encrypt(question2);
        const answer2Encrypted = encrypt(answer2);
        body.question2 = question2Encrypted;
        body.answer2 = answer2Encrypted;
    }
    return {
        type: Actions.processAuthQuestions,
        payload: {
            request: {
                method: 'POST',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/processAuthQuestions',
                headers: {
                    //simple CORS requests can only use text/plain, 
                    //application/x-www-form-urlencoded or multipart/form-data
                    'Content-Type': 'text/plain'
                },
                params: params,
                data: body,
            }
        }
    }
}

export function sendForgotAnswersEmail(account) {
    return {
        type: Actions.sendForgotAnswersEmail,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/sendResetQuestionsEmail',
                params: {
                    SID: process.env.REACT_APP_SID,
                    account
                }
            }
        }
    }
}

export function getAuthCodeData(authCode, authConfigToken) {
    return {
        type: Actions.getAuthCodeData,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/authCode',
                params: {
                    SID: process.env.REACT_APP_SID,
                    authenticationCode: authCode,
                    authConfigToken: authConfigToken,
                }
            }
        }
    }
}

export function decryptSsoToken(token, authConfigToken) {
    return {
        type: Actions.decryptSsoToken,
        payload: {
            request: { 
                method: 'POST',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_POINTS_SERVICES_PATH +'/decryptSsoToken',
                params: {
                    SID: process.env.REACT_APP_SID,
                },
                data: {
                    token: token,
                    authConfigToken: authConfigToken,
                }
            }
        }
    }
}

export function enroll(pages, values, partnerCode, enrollSource, authConfigToken) {
    let body = {};
    let subscriptions = [];
    const subscriptionFieldList = ['OPST','UADL','MPPT','MPPR']; //onlineStatement, newsAndOffers, specialDeals, tripNotes
    for(const pageIndex in pages){
        const page = pages[pageIndex];
        for(const fieldIndex in page.enrollmentPageFields){
            const field = page.enrollmentPageFields[fieldIndex];
            let value = values[field.fieldName];

            //All five questions and answers are handled as a single field
            if(field.fieldName === 'questions' && values.securityQuestion1 && values.securityAnswer1){
                try{
                    body.questions = [
                        {questionKey: encrypt(values.securityQuestion1), answerKey: encrypt(values.securityAnswer1)},
                        {questionKey: encrypt(values.securityQuestion2), answerKey: encrypt(values.securityAnswer2)},
                        {questionKey: encrypt(values.securityQuestion3), answerKey: encrypt(values.securityAnswer3)},
                        {questionKey: encrypt(values.securityQuestion4), answerKey: encrypt(values.securityAnswer4)},
                        {questionKey: encrypt(values.securityQuestion5), answerKey: encrypt(values.securityAnswer5)},
                    ];
                } catch(error) {
                    console.error(error);
                }
                continue;
            }

            if(field.fieldName === 'password' && value){
                try{
                    body.password = encrypt(value);
                } catch(error) {
                    console.error(error);
                }
                continue;
            }

            //Don't include blank fields
            if(!value){
                continue;
            }

            //Pass subscriptions as a list of selected fields
            if(subscriptionFieldList.includes(field.fieldName) && values[field.fieldName]){
                subscriptions.push(field.fieldName);
                continue;
            }

            //Filter all non numeric characters from phone numbers (e.g. "(416) 555-7555" > "4165557555")
            if(field.type === 'tel'){
                value = value.replace('/\D/g', '');
            }
            body[field.fieldName] = value;
        }
    }
    body.subscriptions = subscriptions;
    body.partnerCode = partnerCode;
    body.enrollSource = enrollSource;
    body.authConfigToken = authConfigToken;

    //Add homePhoneDeviceTypeCode (Widgets.MemberEnrollment)
    if(body.homePhoneNumber){
        body.homePhoneDeviceTypeCode = 'PH';
    }

    //Add businessPhoneDeviceTypeCode (Widgets.MemberEnrollment)
    if(body.businessPhoneNumber){
        body.businessPhoneDeviceTypeCode = 'PH';
    }
    
    return {
        type: Actions.enroll,
        payload: {
            request: {
                method: 'POST',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/enrollAccount_v3',
                params: {
                    SID: process.env.REACT_APP_SID,
                },
                data: body
            }
        }
    }
}

export function getAllSecurityQuestions() {
    return {
        type: Actions.getAllSecurityQuestions,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/getAllSecurityQuestions',
                params: {
                    SID: process.env.REACT_APP_SID,
                }
            }
        }
    }
}

export function validatePasswordStrength(password) {
    if(!password){
        return {
            type: "NO_OP",
            comment: "Dont attempt to validate password strength if password is empty."
        };
    }

    const encryptedPassword = encrypt(password);

    return {
        type: Actions.validatePasswordStrength,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/validatePasswordStrength',
                params: {
                    SID: process.env.REACT_APP_SID,
                    password: encryptedPassword,
                },
                data: {}
            }
        }
    }
}

export function updatePassword(sidOnlineAuthCode, password) {
    const encryptedPassword = encrypt(password);
    return {
        type: Actions.updatePassword,
        payload: {
            request: {
                method: 'POST',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/updatePassword_v2',
                params: {
                    SID: process.env.REACT_APP_SID,
                    authenticationCode: sidOnlineAuthCode,
                },
                data: {
                    newPassword: encryptedPassword,
                }
            }
        }
    }
}

export function getProfile(sidOnlineAuthCode) {
    return {
        type: Actions.getProfile,
        payload: {
            request: {
                method: 'GET',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/getProfile_v2',
                params: {
                    SID: process.env.REACT_APP_SID,
                    authenticationCode: sidOnlineAuthCode,
                    includeSecurityUpdate: true,
                }
            }
        }
    }
}

export function removeAccountItemFromUpdateList(accountItem) {
    return {
        type: Actions.removeAccountItemFromUpdateList,
        payload: accountItem
    }
}

export function updateEmail(sidOnlineAuthCode, currentEmail, newEmail) {
    return {
        type: Actions.updateEmail,
        payload: {
            request: {
                method: 'POST',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/updateSecurityEmail',
                params: {
                    SID: process.env.REACT_APP_SID,
                    authenticationCode: sidOnlineAuthCode,
                },
                data: {
                    currentEmail: currentEmail,
                    newEmail: newEmail,
                }
            }
        }
    }
}

export function setSecurityQuestions(sidOnlineAuthCode, values) {
    const questions = [
        {questionKey: values.securityQuestion1, answerKey: values.securityAnswer1},
        {questionKey: values.securityQuestion2, answerKey: values.securityAnswer2},
        {questionKey: values.securityQuestion3, answerKey: values.securityAnswer3},
        {questionKey: values.securityQuestion4, answerKey: values.securityAnswer4},
        {questionKey: values.securityQuestion5, answerKey: values.securityAnswer5},
    ];
    
    return {
        type: Actions.setSecurityQuestions,
        payload: {
            request: {
                method: 'POST',
                url: process.env.REACT_APP_CREW_SERVICES_URL + process.env.REACT_APP_PARTNER_SERVICES_PATH + '/setSecurityQuestions',
                params: {
                    SID: process.env.REACT_APP_SID,
                    authenticationCode: sidOnlineAuthCode,
                },
                data: {
                    questions: questions,
                }
            }
        }
    }
}