import React, { Component } from 'react'
import { Formik, Form } from 'formik'
import Container from '../Container'
import { Redirect } from 'react-router-dom'
import SetPasswordField from '../SetPasswordField'
import { updatePassword } from '../../actions'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Title from '../styling/Title'
import Button from '../styling/Button'
import StyledErrorMessage from '../styling/ErrorMessage'


export class UpdatePassword extends Component {
    render() {
        if(!this.props.sidOnlineAuthCode){
            window.newrelic.interaction().save();
            return(<Redirect to="/login"/>);
        }

        const { t } = this.props;
        if(this.props.updatePasswordSuccess){
            window.newrelic.interaction().save();
            return (<Redirect to={{ pathname:"/terminate",  state: {message: t('Your password has been reset'), returnToLogin: true} }} />);
        }
        
        return (
            <Container>
                <Title>{t('Update Password')}</Title>
                <Formik
                    initialValues={{ password: '', confirmPassword: '' }}
                    onSubmit={ (values, actions) => this.props.updatePassword(this.props.sidOnlineAuthCode, values.password) }
                >
                    {({ values, setFieldValue, setFieldTouched, setFieldError, errors, touched }) => (
                    <Form>
                        <SetPasswordField setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} setFieldError={setFieldError} 
                        values={values} key='password' errors={errors} touched={touched} 
                        />
                        {this.props.updatePasswordError && 
                            <StyledErrorMessage>{this.props.updatePasswordError}</StyledErrorMessage>
                        }
                        <Button type="submit" loading={this.props.loading}>{t('Submit')}</Button>
                    </Form> 
                    )}
                </Formik>
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
   return {
      updatePasswordSuccess: state.Store.password.updatePasswordSuccess,
      updatePasswordError: state.Store.password.error,
      sidOnlineAuthCode: state.Store.login.sidOnlineAuthCode,
      sidOfflineAuthCode: state.Store.login.sidOfflineAuthCode,
      loading: state.Store.password.loading,
   }
}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      updatePassword: (sidOnlineAuthCode, password) => { dispatch(updatePassword(sidOnlineAuthCode, password)) },
   }
}

const trans = withTranslation('updatePassword')(UpdatePassword);
export default connect(
   mapStateToProps,
   mapDispatchToProps
)(trans)