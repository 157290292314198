import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './components/Login'
import LoginSecurityQuestions from './components/LoginSecurityQuestions'
import PasswordRecovery from './components/passwordRecovery/PasswordRecovery'
import PasswordRecoverySecurityQuestions from './components/passwordRecovery/SecurityQuestions'
import PasswordRecoveryUpdatePassword from './components/passwordRecovery/UpdatePassword'
import Enroll from './components/Enroll'
import EnrollConfirm from './components/EnrollConfirm'
import AccountUpdatesUpdateEmail from './components/accountUpdates/UpdateEmail'
import AccountUpdatesUpdatePassword from './components/accountUpdates/UpdatePassword'
import AccountUpdatesUpdateSecurityQuestions from './components/accountUpdates/UpdateSecurityQuestions'
import Terminate from './components/Terminate.jsx'
import ErrorPage from './components/ErrorPage'
import withTracker from './analytics/withTracker'


class ApplicationRouter extends Component {
  render() {
     return (
        <BrowserRouter>
          <div>
             <Switch>
                <Route exact path="/" component={withTracker(Login)} />
                <Route exact path="/login" component={withTracker(Login)} />
                <Route exact path="/connect" component={withTracker(Login)} />
                <Route exact path="/loginSecurityQuestions" component={withTracker(LoginSecurityQuestions)} />
                <Route exact path="/enroll" component={withTracker(Enroll)} />
                <Route exact path="/enrollConfirm" component={withTracker(EnrollConfirm)} />

                <Route exact path="/passwordRecovery" component={withTracker(PasswordRecovery)} />
                <Route exact path="/passwordRecovery/securityQuestions" component={withTracker(PasswordRecoverySecurityQuestions)} />
                <Route exact path="/passwordRecovery/updatePassword" component={withTracker(PasswordRecoveryUpdatePassword)} />

                <Route exact path="/accountUpdates/updateEmail" component={withTracker(AccountUpdatesUpdateEmail)} />
                <Route exact path="/accountUpdates/updatePassword" component={withTracker(AccountUpdatesUpdatePassword)} />
                <Route exact path="/accountUpdates/updateSecurityQuestions" component={withTracker(AccountUpdatesUpdateSecurityQuestions)} />

                <Route exact path="/terminate" component={withTracker(Terminate)} />
                <Route exact path="/error" component={withTracker(ErrorPage)} />

                <Route component={ErrorPage} />
             </Switch>
          </div>
        </BrowserRouter>
     )
  }
}

export default ApplicationRouter
