import React, { Component } from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import Field from './styling/Field'
import LargeSpinner from './styling/LargeSpinner'
import StyledErrorMessage from './styling/ErrorMessage'

import { getAllSecurityQuestions } from '../actions'



export class SetSecurityQuestionsField extends Component {

    componentDidMount() {
        this.props.getAllSecurityQuestions();
    }

    addQuestion(selectedId, val, text) {
        for (var i = 1; i <= 5; i++) {
            var id = 'securityQuestion' + i;
            if (id === selectedId) {
                continue;
            }

            $('#' + id).append($('<option/>', {
                value: val,
                text: text
            }));
        }
    }

    removeQuestion(selectedId, val) {
        for (var i = 1; i <= 5; i++) {
            var id = 'securityQuestion' + i;
            if (id === selectedId) {
                continue;
            }

            $("#" + id + " option[value='" + val + "']").remove();
        }
    }

    sortQuestions() {
        for (var i = 1; i <= 5; i++) {
        var id = "securityQuestion" + i
        var element = $("#" + id);
        var selectedVal = element.val();

        var options = $('option', element);

        var arrVals = [];
        options.each(function() {
            arrVals.push({
            val: $(this).val(),
            text: $(this).text()
            });
        });

        arrVals.sort(function(a, b) {
            if (a.text === "Select question") {
            return -1
            }
            if (b.text === "Select question") {
            return 1;
            }
            if (a.text === b.text) {
            return 0;
            }
            if (a.text > b.text) {
            return 1;
            }
            return -1;
        });

        for (var j = 0; j < arrVals.length; j++) {
          $(options[j]).val(arrVals[j].val).text(arrVals[j].text);
      }

      element.val(selectedVal);
      }
   }

   populateAnswers(questionId, questionKey) {
      var answerId = questionId.replace("Question", "Answer");
      var answerDropDown = $("#" + answerId);
      answerDropDown.empty();
      answerDropDown.append("<option value='empty'>" + this.props.t('Select answer') + "</option>");
      answerDropDown.val("empty");
      answerDropDown.change();

      if (!questionKey) {
        return false;
      }

      const questions = this.props.securityQuestions.securityQuestions;
      const question = questions.find((question) => {
        return question.QuestionKey === questionKey
      })
      let answers = question.Answers;
      answers.forEach(function(answer){
        answerDropDown.append("<option value='" + answer.AnswerKey + "'>" + answer.AnswerText + "</option>");
      });
   }

   questionChange(args) {
      var previousVal = $(args).attr('previousValue');
      var previousText = $(args).attr('previousText');
      var newOption = $(args).find(':selected');
      var newVal = newOption.val();
      var newText = newOption.text();
      var id = $(args).attr('id');

      if (previousVal) {
         this.addQuestion(id, previousVal, previousText);
      }

      if (newVal) {
         this.removeQuestion(id, newVal);
      }

      this.sortQuestions();

      this.populateAnswers(id, newVal);

      $(args).attr('previousValue', newVal);
      $(args).attr('previousText', newText);
   }

   populateQuestion(key, val) {
      var element = $("#" + key);
      element.val(val);
      if (val) {
         var text = $("#" + key + " option:selected").text();
         element.attr('previousValue', element.val());
         element.attr('previousText', text);
         var id = element.attr('id');
         if (id) {
            this.populateAnswers(id, element.val());
         }
      }
   }

   render() {
      if(this.props.loading){
         return(<LargeSpinner/>);
      }

      if(!this.props.loading && !(this.props.securityQuestions && this.props.securityQuestions.securityQuestions && Array.isArray(this.props.securityQuestions.securityQuestions))){
        console.error("SetSecurityQuestionsField failed to fetch security questions.");
        //TODO: what should we do if there's a failure in this component? Display error message within this section of the form? Bubble the error up and display the error with other enroll error? Reload the page to try again? Up to a max so it wouldn't loop?
        return(
           <StyledErrorMessage>{"Failed to load security questions"}</StyledErrorMessage>
        )
      }

      const { t } = this.props;

      return(
         <div>
            {this.props.securityQuestions.error && 
               <StyledErrorMessage>{this.props.securityQuestions.error}</StyledErrorMessage>
            }
            {
               (this.props.securityQuestions && this.props.securityQuestions.securityQuestions && Array.isArray(this.props.securityQuestions.securityQuestions))
               && <div>
                  {[1,2,3,4,5].map(i => {
                     const questionId = 'securityQuestion' + i;
                     const answerId = 'securityAnswer' + i;
                     return (<div key={questionId}>
                        <Field id={questionId} name={questionId} component='select'
                           handleChange={(event) => {
                              this.props.setFieldValue(questionId, event.target.value);
                              this.questionChange(event.target);
                           }}
                           handleBlur={(event) => {this.props.setFieldTouched(questionId, true);}}
                           label={'* ' + t('Security Question ' + i)}
                           errors={this.props.errors}
                           touched={this.props.touched}
                        >
                           <option value="">{t('Select question')}</option>
                           {
                              this.props.securityQuestions.securityQuestions.map((question) => {
                                 return(<option value={question.QuestionKey} key={question.QuestionKey}>{question.QuestionText}</option>);
                              })
                           }
                        </Field>
                        <Field id={answerId} name={answerId} component='select' label="" errors={this.props.errors} touched={this.props.touched} >
                           <option value="">{this.selectAnswer}</option>
                        </Field>
                     </div>);
                  })}
               </div>
            }
         </div>
      );
   }
}

function mapStateToProps(state, ownProps) {
   return {
        securityQuestions: state.Store.securityQuestions
   };
}

function mapDispatchToProps(dispatch, ownProps) {
   return {
      getAllSecurityQuestions: () => {dispatch(getAllSecurityQuestions())},
   };
}

const trans = withTranslation('setSecurityQuestionsField')(SetSecurityQuestionsField);
export default connect(
   mapStateToProps,
   mapDispatchToProps
)(trans);